import axios from "axios";
import React, { useEffect, useState } from "react";
import { MdContentCopy } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import CloseButton from "../Student_New/CloseButton";
import PropagateLoader from "react-spinners/PropagateLoader";
import PreviewModel from "../Modal/PreviewModel";
import Pagination from "../Student_New/Pagination";
import { RxDotFilled, RxPencil1 } from "react-icons/rx";
import MonthCalculator from "../Student_New/MonthCalculator";
import SeeMoreLess from "../Student_New/SeeMoreLess";
import { RiDeleteBinLine } from "react-icons/ri";
import { MONTH_DETAILS } from "../data";
import { useDispatch, useSelector } from "react-redux";
import { loadUser } from "../../actions/userActions";

const PreviewCandidate = () => {
  const [resumeData, setResumeData] = useState(null); // Initialize as null to avoid undefined errors.
  const [isExpanded, setIsExpanded] = useState(false);
  const [hoveredProject, setHoveredProject] = useState(null);
  const [selectedProject, setSelectedProject] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [hoverText, setHoverText] = useState("Copy to clipboard!");
  const [linkCopied, setLinkCopied] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const param = useParams();
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);
  useEffect(() => {
    dispatch(loadUser());
  }, []);

  const projectsPerPage = 3;
  const totalPages = Math.ceil(
    resumeData?.resume?.Portfolio.length / projectsPerPage
  );

  const displayedProjects = resumeData?.resume?.Portfolio?.slice(
    (activePage - 1) * projectsPerPage,
    activePage * projectsPerPage
  );

  const ChatToUser = async (data) => {
    try {
      const response = await axios.post("/api/user/createRoom", {
        userId: resumeData._id,
        employerId: user && user._id,
        jobId: "6613d85f0ef12e507f453a46",
      });
      if (response.data.success) {
        navigate("/messageEmployer", { state: response.data.data });
      }
    } catch (error) {
      console.error("Error occurred while sending request:", error);
    }
  };

  const handleProjectClick = async (project) => {
    //console.log(project, "jfkdk");
    setLoading(true);
    setSelectedProject(null);
    await new Promise((resolve) => setTimeout(resolve, 500));
    setSelectedProject(project);
    setLoading(false);
  };

  const calculateTotalExperience = (internships, jobs) => {
    let totalMonths = 0;

    // Function to calculate the difference in months between two dates
    function monthDiff(d1, d2) {
      let months;
      months = (d2.getFullYear() - d1.getFullYear()) * 12;
      months -= d1.getMonth();
      months += d2.getMonth();
      return months <= 0 ? 0 : months;
    }

    // Calculate total months for internships
    internships.forEach((internship) => {
      const startDate = new Date(internship.startDate);
      const endDate = new Date(internship.endDate);
      totalMonths += monthDiff(startDate, endDate);
    });

    // Calculate total months for jobs
    jobs.forEach((job) => {
      const startDate = new Date(job.startDate);
      const endDate = new Date(job.endDate);
      totalMonths += monthDiff(startDate, endDate);
    });

    // Calculate years and remaining months
    const years = Math.floor(totalMonths / 12);
    const months = totalMonths % 12;

    // Construct the result string
    let result = "(";
    if (years === 1) {
      result += years + " YEAR";
    } else if (years > 1) {
      result += years + " YEARS";
    }

    if (months === 1) {
      result += " " + months + " MONTH";
    } else if (months > 1) {
      result += " " + months + " MONTHS";
    }
    result += ")";

    return result;
  };

  const tooltipStyles = {
    position: "absolute",
    top: "100%",
    left: "80%",
    transform: "translateX(-50%)",
    marginBottom: "8px",
    padding: "5px 10px",
    background: "#555",
    color: "#fff",
    borderRadius: "4px",
    fontSize: "12px",
    whiteSpace: "nowrap",
    zIndex: 10,
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)", // Optional: adds shadow to tooltip
  };

  const getYouTubeEmbedUrl = (url) => {
    try {
      const urlObj = new URL(url);
      let videoId;

      if (urlObj.hostname === "youtu.be") {
        videoId = urlObj.pathname.substring(1);
      } else if (urlObj.pathname.includes("/shorts/")) {
        videoId = urlObj.pathname.split("/shorts/")[1];
      } else {
        videoId = urlObj.searchParams.get("v");
      }

      return `https://www.youtube.com/embed/${videoId}`;
    } catch (e) {
      console.error("Invalid URL", e);
      return null;
    }
  };

  useEffect(() => {
    const fetchResumeData = async () => {
      try {
        if (param.CandidateId) {
          const { data } = await axios.get(
            `/api/user/getResumebyId/${param.CandidateId}`,
            {
              headers: {
                "Content-Type": "application/json",
                token: localStorage.getItem("token"),
              },
            }
          );
          setResumeData(data.user);
        }
      } catch (e) {
        console.error(e);
        toast.error("Something went wrong while fetching the resume");
      }
    };

    fetchResumeData();
  }, [param]);

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(
      resumeData?.resume?.PersonalDetails?.videoIntroduction
    );
    setLinkCopied(true);
    setHoverText("Copied!");
    setShowTooltip(true);
    setTimeout(() => {
      setHoverText("Copy to clipboard!");
      setLinkCopied(false);
      setShowTooltip(false);
    }, 800);
  };

  return (
    <>
      <div
        className="modal fade"
        id="previewmodalresume"
        tabIndex="-1"
        aria-labelledby="previewModalresumeLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog custom-modal-dialog unique-modal-dialog modal-dialog-centered width-settingofmodal">
          <div
            className="modal-content px-3 py-3"
            style={{
              borderRadius: "15px",
              // // marginTop: "10px",
              height: "95vh",
            }}
          >
            <div className="preview-header">
              <h2 style={{ fontSize: "28px" }}>
                {selectedProject?.projectTitle}
              </h2>

              <CloseButton className="btnclosepreview" />
            </div>
            <div
              className="modal-body"
              style={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                overflowY: "auto",
                paddingLeft: "50px",
                paddingRight: "50px",
              }}
            >
              {loading ? (
                <div
                  className=""
                  style={{ textAlign: "center", paddingBottom: "220px" }}
                >
                  <p
                    style={{
                      color: "#4B92C8",
                      fontWeight: "500",
                      fontSize: "20px",
                      marginLeft: "43px",
                      marginBottom: "5px",
                      marginTop: "220px",
                    }}
                  >
                    Loading..
                  </p>
                  <PropagateLoader
                    color={"#4B92C8"}
                    loading={true}
                    size={15}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                    style={{ marginLeft: "27px" }}
                  />
                </div>
              ) : (
                selectedProject && (
                  <div className={`fade-in ${!loading ? "show" : ""}`}>
                    <div className="mb-3 flex-container-portfolio">
                      <div
                        className="leftsectionpreviewport"
                        style={{
                          flex: 1,
                          marginRight: "20px",
                          position: "sticky",
                          top: "0",
                          alignSelf: "flex-start",
                        }}
                      >
                        {/* <div
                        style={{ marginTop: "15px", marginBottom: "40px" }}
                      >

                      modfhdjhfujdyfydyfu
                        <h2 style={{ fontSize: "28px" }}>
                          {selectedProject.projectTitle}
                        </h2>
                      </div> */}
                        {selectedProject.yourRole !== "" && (
                          <div style={{ marginBottom: "25px" }}>
                            <p style={{ fontSize: "16px" }}>
                              <b>My role:</b> {selectedProject.yourRole}
                            </p>
                          </div>
                        )}
                        {selectedProject.projectDescription !== "" && (
                          <div style={{ marginBottom: "25px" }}>
                            <p style={{ fontSize: "16px" }}>
                              <b>Project Description:</b>{" "}
                              <span
                                dangerouslySetInnerHTML={{
                                  __html:
                                    selectedProject.projectDescription.replace(
                                      /\n/g,
                                      "<br />"
                                    ),
                                }}
                              />
                              {/* {selectedProject.projectDescription} */}
                            </p>
                          </div>
                        )}
                        <div style={{ marginBottom: "25px" }}>
                          <h4>Skills and deliverables</h4>
                          <div
                            style={{
                              display: "flex",
                              flexWrap: "wrap",
                            }}
                          >
                            {selectedProject.skills.map((skill) => (
                              <span
                                key={skill.value}
                                className="post1234forresume mr-4 mt-3"
                              >
                                {skill.label}
                              </span>
                            ))}
                          </div>
                        </div>
                        <hr
                          //className="dropdown-divider1 mb-4 mt-3"
                          style={{
                            height: "0.1px",
                            color: "rgb(185, 185, 185)",
                          }}
                        />
                      </div>
                      <div
                        className="mb-3 rightsectionpreviewport"
                        style={{
                          flex: 2,
                          minHeight: "240px",
                          flexDirection: "column",
                        }}
                      >
                        {/* {portfolioDetailsData?.map((item, index) => (
                        <div key={index}>
                          {item?.type === "image" && (
                            <div>
                              {item?.images?.map((img, idx) => (
                                <div
                                  key={idx}
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    marginBottom: "25px",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <img
                                    key={idx}
                                    src={img}
                                    alt={`Project Photo ${idx + 1}`}
                                    style={{
                                      marginBottom: "10px",
                                      height: "400px",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      width: "100%",
                                    }}
                                  />
                                </div>
                              ))}
                            </div>
                          )}
                          {item?.type === "video" && (
                            <div>
                              {item?.videos?.map((vd, idx) => (
                                <div
                                  key={idx}
                                  style={{ marginBottom: "10px" }}
                                >
                                  <iframe
                                    width="100%"
                                    height="400"
                                    src={getYouTubeEmbedUrl(vd)}
                                    title="Preview Introduction"
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                  ></iframe>
                                </div>
                              ))}
                            </div>
                          )}
                          {item?.type === "text-content" && (
                            <div>
                              {item?.textContents?.map((txt, idx) => (
                                <div key={idx}>
                                  <h5
                                    style={{
                                      fontSize: "25px",
                                      fontWeight: "500",
                                      marginBottom: "15px",
                                      textAlign: "justify",
                                    }}
                                  >
                                    {txt.heading}
                                  </h5>
                                  <p
                                    style={{
                                      fontSize: "18px",
                                      marginBottom: "15px",
                                    }}
                                  >
                                    <span
                                      dangerouslySetInnerHTML={{
                                        __html: txt.content.replace(
                                          /\n/g,
                                          "<br />"
                                        ),
                                      }}
                                    />
                                  </p>
                                </div>
                              ))}
                            </div>
                          )}
                          {item?.type === "artical" && (
                            <div>
                              {item?.webLinks?.map((link, idx) => (
                                <div
                                  key={idx}
                                  style={{ marginBottom: "25px" }}
                                >
                                  <a
                                    href={link?.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ textDecoration: "none" }}
                                  >
                                    <div
                                      className="preview-container form-group mt-4 mb-2"
                                      style={{
                                        backgroundColor: "#f9f9f9",
                                        paddingTop: "10px",
                                        paddingBottom: "10px",
                                        paddingLeft: "5px",
                                        paddingRight: "10px",
                                        borderRadius: "8px",
                                        cursor: "pointer",
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          gap: "10px",
                                        }}
                                      >
                                        {link.image && (
                                          <img
                                            src={link.image}
                                            alt="Link preview"
                                            style={{
                                              width: "100px",
                                              height: "100px",
                                              borderRadius: "5px",
                                            }}
                                          />
                                        )}
                                        <div
                                          style={{
                                            display: "flex",
                                            flexDirection: "column",
                                          }}
                                        >
                                          <h3
                                            style={{
                                              fontSize: "16px",
                                              color: "#2092c7",
                                              fontWeight: "500",
                                              textDecoration: "underline",
                                              marginBottom: "8px",
                                            }}
                                          >
                                            {link.title}
                                          </h3>
                                          <p
                                            style={{
                                              fontSize: "14px",
                                              color: "#555",
                                            }}
                                          >
                                            {link.description}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </a>
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      ))} */}
                        {/* 
                          {selectedProject.textContent &&
                            selectedProject.textContent.length > 0 && (
                              <div style={{ marginBottom: "25px" }}>
                                {selectedProject.textContent.map(
                                  (text, index) => (
                                    <div key={index}>
                                      <h5
                                        style={{
                                          fontSize: "25px",
                                          fontWeight: "500",
                                          marginBottom: "15px",
                                          textAlign: "justify",
                                        }}
                                      >
                                        {text.heading}
                                      </h5>
                                      <p
                                        style={{
                                          fontSize: "18px",
                                          marginBottom: "15px",
                                        }}
                                      >
                                        <span
                                          dangerouslySetInnerHTML={{
                                            __html: text.content.replace(
                                              /\n/g,
                                              "<br />"
                                            ),
                                          }}
                                        />
                                      </p>
                                    </div>
                                  )
                                )}
                              </div>
                            )}
                          {selectedProject.photo &&
                            selectedProject.photo.length > 0 && (
                              <div style={{ marginBottom: "25px" }}>
                                {selectedProject.photo.map((imgSrc, index) => (
                                  <div
                                    key={index}
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      marginBottom: "25px",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <img
                                      src={imgSrc.url}
                                      alt={`Project Photo ${index + 1}`}
                                      style={{
                                        marginBottom: "10px",
                                        height: "400px",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        width: "100%",
                                      }}
                                    />
                                  </div>
                                ))}
                              </div>
                            )}
                          {selectedProject.videoLink &&
                            selectedProject.videoLink.length > 0 && (
                              <div style={{ marginBottom: "25px" }}>
                                {selectedProject.videoLink.map(
                                  (link, index) => (
                                    <div
                                      key={index}
                                      style={{ marginBottom: "10px" }}
                                    >
                                      <iframe
                                        width="100%"
                                        height="400"
                                        src={getYouTubeEmbedUrl(link)}
                                        title="Preview Introduction"
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                      ></iframe>
                                    </div>
                                  )
                                )}
                              </div>
                            )}

                          {selectedProject?.webLink &&
                            selectedProject?.webLink.length > 0 && (
                              <div style={{ marginBottom: "25px" }}>
                                {selectedProject?.webLink.map((link, index) => (
                                  <div
                                    key={index}
                                    style={{ marginBottom: "25px" }}
                                  >
                                    {previewData3 &&
                                    previewData3[index] &&
                                    !error3 ? ( // Add check for previewData1
                                      <a
                                        href={link}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{ textDecoration: "none" }}
                                      >
                                        <div
                                          className="preview-container form-group mt-4 mb-2"
                                          style={{
                                            backgroundColor: "#f9f9f9",
                                            paddingTop: "10px",
                                            paddingBottom: "10px",
                                            paddingLeft: "5px",
                                            paddingRight: "10px",
                                            borderRadius: "8px",
                                            cursor: "pointer",
                                          }}
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                              gap: "10px",
                                            }}
                                          >
                                            {previewData3[index].image && (
                                              <img
                                                src={previewData3[index].image}
                                                alt="Link preview"
                                                style={{
                                                  width: "100px",
                                                  height: "100px",
                                                  borderRadius: "5px",
                                                }}
                                              />
                                            )}
                                            <div
                                              style={{
                                                display: "flex",
                                                flexDirection: "column",
                                              }}
                                            >
                                              <h3
                                                style={{
                                                  fontSize: "16px",
                                                  color: "#2092c7",
                                                  fontWeight: "500",
                                                  textDecoration: "underline",
                                                  marginBottom: "8px",
                                                }}
                                              >
                                                {previewData3[index].title}
                                              </h3>
                                              <p
                                                style={{
                                                  fontSize: "14px",
                                                  color: "#555",
                                                }}
                                              >
                                                {
                                                  previewData3[index]
                                                    .description
                                                }
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </a>
                                    ) : (
                                      <p>Loading...</p>
                                    )}
                                  </div>
                                ))}
                              </div>
                            )} */}
                        <PreviewModel portfolioDetails={selectedProject} />

                        <hr
                          className="dropdown-divider1 mb-4 mt-3"
                          style={{
                            height: "0.05px",
                            color: "rgb(185, 185, 185)",
                          }}
                        />
                        <div className="mb-3" style={{ marginTop: "35px" }}>
                          {/* <p style={{ fontSize: "18px", marginBottom: '15px' }}>More by <span style={{ color: "#2092c7" }}>{resumeData.PersonalDetails.firstName} {resumeData.PersonalDetails.lastName}</span></p> */}
                          <p style={{ fontSize: "18px", marginBottom: "15px" }}>
                            More by{" "}
                            <a href={"#"} style={{ color: "#2092c7" }}>
                              {resumeData?.resume?.PersonalDetails.firstName}{" "}
                              {resumeData?.resume?.PersonalDetails.lastName}
                            </a>
                          </p>
                          <div className="row">
                            {displayedProjects
                              ?.filter(
                                (project) => project._id !== selectedProject._id
                              ) // Exclude selected project
                              .slice(0, 3) // Get next 3 projects
                              .map((project) => (
                                <div
                                  className="col-md-4"
                                  key={project._id}
                                  style={{ cursor: "pointer" }}
                                  onClick={() => handleProjectClick(project)}
                                >
                                  <div className="card">
                                    <img
                                      className="card-img-top"
                                      src={project.selectedThumbnail.url}
                                      alt={`Project ${project._id}`}
                                    />
                                    <div className="card-body">
                                      <p
                                        className="card-text"
                                        style={{ color: "#2092c7" }}
                                      >
                                        {project.projectTitle}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </div>
      {resumeData ? (
        <div className="reviewpage container-fluid px-5 py-5">
          <div className="r1 container">
            <div className="section1 mt-4 mb-3 flex items-center">
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div
                  className="float-right "
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <div
                    className=""
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <button
                      className="shortcut-newappliedjob"
                      // onClick={() => handleChatClick(state2?.user?._id, state2?.employer, state2?.job?._id, state2?._id , navigate)}
                      // onClick={() => setOpenPopup(!openPopup)}
                      onClick={ChatToUser}
                      style={{ color: "#1177d6" }}
                    >
                      Message
                    </button>
                  </div>
                </div>
                <h3 className="title123 pt-2" style={{ display: "flex" }}>
                  <>
                    <div
                      className=""
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      {resumeData?.resume?.PersonalDetails?.photo?.url !==
                        "" && (
                        <img
                          src={resumeData?.resume?.PersonalDetails?.photo?.url}
                          alt="Profile"
                          // style={{ width: '131px',height:'137px', objectFit: 'cover', borderRadius:'50%', border:'2px solid gray'}}
                          className="phototodisplayindesktop"
                          style={{
                            boxShadow: "0 0 5px rgba(0,0,0,0.2)",
                            border: "4px solid white",
                            marginRight: "1rem",
                          }}
                        />
                      )}
                      <div>
                        <div style={{ marginBottom: "0.7rem" }}>
                          {resumeData.name}
                        </div>
                        <p style={{ fontSize: "18px", color: "black" }}>
                          <b>
                            {
                              resumeData?.resume?.PersonalDetails
                                ?.professionalTitle
                            }
                          </b>
                        </p>
                        {resumeData?.resume?.PersonalDetails?.minimumRate && (
                          <p
                            style={{
                              marginTop: "-8px",
                              fontSize: "17px",
                              color: "black",
                            }}
                          >
                            <b>
                              $
                              {resumeData?.resume?.PersonalDetails?.minimumRate}
                            </b>
                            <b>/hour</b>
                          </p>
                        )}
                      </div>
                    </div>
                    {/* <div
                  className="float-right w-1/2"
                  style={{
                    width: "50%",
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  <div
                    className=""
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    {resumeData?.resume?.PersonalDetails?.photo?.url !== "" && (
                      <img
                        src={resumeData?.resume?.PersonalDetails?.photo?.url}
                        alt="Profile"
                        // style={{ width: '131px',height:'137px', objectFit: 'cover', borderRadius:'50%', border:'2px solid gray'}}
                        className="phototodisplayindesktop"
                        style={{
                          boxShadow: "0 0 5px rgba(0,0,0,0.2)",
                          border: "4px solid white",
                        }}
                      />
                    )}
                  </div>
                </div> */}
                  </>
                </h3>
              </div>
              <div className="">
                {resumeData?.resume?.PersonalDetails?.videoIntroduction && (
                  <>
                    <p
                      style={{
                        fontWeight: "500",
                        marginTop: "20px",
                        fontSize: "22px",
                        color: "black",
                      }}
                    >
                      Video Introduction
                    </p>
                    {/* <p style={{ marginTop: '-6px', fontSize: "17px",}}><a href={resumeData.PersonalDetails.videoIntroduction} target='__blank' style={{textDecoration:'none', display:'flex', gap:'10px',alignItems:'center'}}>
                {'Your Video Introduction'} <FaLink />
                </a></p> */}

                    <p
                      style={{
                        marginTop: "-6px",
                        fontSize: "17px",
                        display: "flex",
                      }}
                    >
                      <a
                        href={
                          resumeData?.resume?.PersonalDetails?.videoIntroduction
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          textDecoration: "none",
                          display: "flex",
                          gap: "10px",
                          alignItems: "center",
                        }}
                      >
                        {"Your Video Introduction"}
                      </a>
                      <span
                        onClick={handleCopyToClipboard}
                        onMouseEnter={() => {
                          if (!linkCopied) setHoverText("Copy to clipboard!");
                          setShowTooltip(true); // Show tooltip on hover
                        }}
                        onMouseLeave={() => setShowTooltip(false)} // Hide tooltip when mouse leaves
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                          marginLeft: "10px",
                          position: "relative",
                          color: "#2092c7",
                        }}
                      >
                        <MdContentCopy />
                        {showTooltip && (
                          <div style={tooltipStyles}>{hoverText}</div>
                        )}
                      </span>
                    </p>
                    <div className="video-wrapperresumevideo">
                      <iframe
                        // width="560"
                        // height="315"
                        src={getYouTubeEmbedUrl(
                          resumeData?.resume?.PersonalDetails?.videoIntroduction
                        )}
                        title="Video Introduction"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      ></iframe>
                    </div>
                  </>
                )}
                {resumeData?.resume?.PersonalDetails?.profileCategories && (
                  <p
                    style={{
                      fontWeight: "500",
                      marginTop: "18px",
                      fontSize: "22px",
                      color: "black",
                    }}
                  >
                    Profile Category
                  </p>
                )}
                {resumeData?.resume?.PersonalDetails?.profileCategories.map(
                  (category, index) => (
                    <div key={category.id} style={{ marginTop: "10px" }}>
                      <p
                        style={{
                          fontSize: "17px",
                          color: "black",
                          fontWeight: "bold",
                        }}
                      >
                        {category?.profile}
                      </p>
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                        }}
                      >
                        {category?.specializations?.map((spec, specIndex) => (
                          <span
                            key={specIndex}
                            className="post1234forresume mr-4 mt-3"
                            style={{ marginBottom: "10px" }}
                          >
                            {spec}
                          </span>
                        ))}
                      </div>
                    </div>
                  )
                )}
                {resumeData?.resume?.PersonalDetails?.profileSummary && (
                  <div
                    style={{
                      marginTop: "10px",
                      fontSize: "15px",
                      color: "black",
                    }}
                  >
                    <p
                      style={{
                        fontWeight: "500",
                        marginTop: "18px",
                        fontSize: "22px",
                        color: "black",
                      }}
                    >
                      Profile Summary
                    </p>
                    <p
                      style={{
                        marginTop: "-6px",
                        fontSize: "17px",
                        color: "black",
                      }}
                    >
                      <span
                        dangerouslySetInnerHTML={{
                          __html: isExpanded
                            ? resumeData?.resume?.PersonalDetails?.profileSummary?.replace(
                                /\n/g,
                                "<br />"
                              )
                            : resumeData?.resume?.PersonalDetails?.profileSummary
                                .slice(0, 330)
                                .replace(/\n/g, "<br />"),
                        }}
                      />
                      {/* {isExpanded ? resumeData.PersonalDetails.profileSummary : resumeData.PersonalDetails.profileSummary.slice(0, 600)} */}
                      {resumeData?.resume?.PersonalDetails?.profileSummary
                        .length > 330 && (
                        <>
                          {/* <span
        onClick={() => setIsExpanded(!isExpanded)}
        style={{ color: '#2092c7', cursor: 'pointer', marginLeft: '5px' ,textDecoration:'underline'}}
      >
        {isExpanded ? 'less' : '...more'}
      </span> */}
                          {!isExpanded && <span>...</span>}
                          <span
                            onClick={() => setIsExpanded(!isExpanded)}
                            style={{
                              color: "#2092c7",
                              cursor: "pointer",
                              textDecoration: "underline",
                            }}
                          >
                            <br />
                            {isExpanded ? "less" : "more"}
                          </span>
                        </>
                      )}
                    </p>
                  </div>
                )}
                {resumeData?.resume?.PersonalDetails?.availability && (
                  <div
                    style={{
                      marginTop: "10px",
                      fontSize: "15px",
                      color: "black",
                    }}
                  >
                    <p
                      style={{
                        fontWeight: "500",
                        marginTop: "18px",
                        fontSize: "22px",
                        color: "black",
                      }}
                    >
                      Availability
                    </p>
                    <p
                      style={{
                        marginTop: "-6px",
                        fontSize: "17px",
                        color: "black",
                      }}
                    >
                      {resumeData?.resume?.PersonalDetails?.availability}
                    </p>
                  </div>
                )}
              </div>

              <hr
                className=" mb-4 mt-5"
                style={{ height: "3.05px", color: "#000" }}
              />
              <div className="">
                <div className="" style={{ minWidth: "150px" }}>
                  <p
                    style={{
                      color: "black",
                      fontSize: "32px",
                      fontWeight: "500",
                    }}
                  >
                    Portfolio
                  </p>
                </div>
                <div className="tab-content">
                  <div id="published" className="tab-pane active">
                    <div className="row">
                      {displayedProjects?.map((project) => (
                        <div
                          className="col-md-4"
                          key={project?.id}
                          style={{
                            cursor: "pointer",
                            positon: "relative",
                          }}
                          onMouseEnter={() => setHoveredProject(project?._id)}
                          onMouseLeave={() => setHoveredProject(null)}
                        >
                          <div className="card portcard">
                            {/* <img className="card-img-top"  src={project.selectedThumbnail.url} alt={`Project ${project._id}`} /> */}
                            <img
                              className={`card-img-top project-image ${
                                hoveredProject === project?._id
                                  ? "dim-image"
                                  : ""
                              }`}
                              src={project?.selectedThumbnail?.url}
                              alt={`Project ${project._id}`}
                            />
                            <div className="card-body">
                              <p
                                className="card-text project-title"
                                style={{ color: "#2092c7" }}
                                data-bs-toggle="modal"
                                data-bs-target="#previewmodalresume"
                                onClick={() => {
                                  setSelectedProject(project);
                                  console.log(project, "ppp");
                                  // let prev = generateDataInSequence(
                                  //   project?.mediaSequence,
                                  //   project
                                  // );
                                  // setPortfolioDetailsData(
                                  //   project?.mediaSequence
                                  // );
                                  // console.log(prev, "preview data");
                                }}
                              >
                                {project?.projectTitle}
                              </p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    {/* custom paginATION */}
                    <div>
                      {totalPages !== 0 && (
                        <Pagination
                          totalPages={totalPages}
                          currentPage={activePage}
                          onPageChange={setActivePage}
                        />
                      )}
                    </div>
                    {/* <nav aria-label="Page navigation">
                            <ul className="pagination pagination-navigation">
                              <li
                                className={`page-item ${
                                  activePage === 1 ? "disabled" : ""
                                }`}
                              >
                                <div
                                  className="page-link"
                                  onClick={() =>
                                    setActivePage((prev) =>
                                      Math.max(prev - 1, 1)
                                    )
                                  }
                                  style={{
                                    borderRadius: "50%",
                                    color:
                                      activePage === 1 ? "gray" : "#2092c7",
                                    pointerEvents:
                                      activePage === 1 ? "none" : "auto",
                                    cursor:
                                      activePage === 1
                                        ? "not-allowed"
                                        : "pointer",
                                  }}
                                >
                                  <IoIosArrowBack />
                                </div>
                              </li>
                              <li
                                className={`page-item ${
                                  activePage === 1 ? "disabled" : ""
                                }`}
                              >
                                <div
                                  className="page-link"
                                  onClick={() =>
                                    setActivePage((prev) =>
                                      Math.max(prev - 1, 1)
                                    )
                                  }
                                  style={{
                                    borderRadius: "50%",
                                    color:
                                      activePage === 1 ? "gray" : "#2092c7",
                                    pointerEvents:
                                      activePage === 1 ? "none" : "auto",
                                    cursor:
                                      activePage === 1
                                        ? "not-allowed"
                                        : "pointer",
                                  }}
                                >
                                  Previous
                                </div>
                              </li>
                              
                              {[...Array(totalPages)].map((_, page) => (
                                <li
                                  key={page}
                                  className="page-item"
                                  onClick={() => setActivePage(page + 1)}
                                >
                                  <div
                                    className="page-link"
                                    style={{
                                      backgroundColor:
                                        activePage === page + 1
                                          ? "#2092c7"
                                          : "transparent",
                                      color:
                                        activePage === page + 1
                                          ? "white"
                                          : "#2092c7",
                                      borderRadius: "50%",
                                      height: "25px",
                                      width: "25px",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    {page + 1}
                                  </div>
                                </li>
                              ))}
                              <li
                                className={`page-item ${
                                  activePage === totalPages
                                    ? "disabled"
                                    : ""
                                }`}
                              >
                                <div
                                  className="page-link"
                                  onClick={() =>
                                    setActivePage((next) =>
                                      Math.min(next + 1, totalPages)
                                    )
                                  }
                                  style={{
                                    borderRadius: "50%",
                                    color:
                                      activePage === totalPages
                                        ? "gray"
                                        : "#2092c7",
                                    pointerEvents:
                                      activePage === totalPages
                                        ? "none"
                                        : "auto",
                                    cursor:
                                      activePage === totalPages
                                        ? "not-allowed"
                                        : "pointer",
                                  }}
                                >
                                  Next
                                </div>
                              </li>
                              <li
                                className={`page-item ${
                                  activePage === totalPages
                                    ? "disabled"
                                    : ""
                                }`}
                              >
                                <div
                                  className="page-link"
                                  onClick={() =>
                                    setActivePage((next) =>
                                      Math.min(next + 1, totalPages)
                                    )
                                  }
                                  style={{
                                    borderRadius: "50%",
                                    color:
                                      activePage === totalPages
                                        ? "gray"
                                        : "#2092c7",
                                    pointerEvents:
                                      activePage === totalPages
                                        ? "none"
                                        : "auto",
                                    cursor:
                                      activePage === totalPages
                                        ? "not-allowed"
                                        : "pointer",
                                  }}
                                >
                                  <IoIosArrowForward />
                                </div>
                              </li>
                            </ul>
                          </nav> */}
                  </div>
                </div>
              </div>

              <hr
                className="dropdown-divider1 mb-4 mt-5"
                style={{ height: "3.05px", color: "#000" }}
              />

              <div class="container" style={{ padding: "25px" }}>
                <div class="row">
                  <div class="col-3" style={{ minWidth: "150px" }}>
                    <p
                      style={{
                        color: "black",
                        fontSize: "22px",
                        letterSpacing: "0.5px",
                        fontWeight: "500",
                      }}
                    >
                      EDUCATION
                    </p>
                  </div>

                  <div class="col-9 mobile-column-to-increase-width">
                    {resumeData && resumeData?.resume?.Education ? (
                      <>
                        {resumeData?.resume?.Education.map((data) => {
                          return (
                            <>
                              <div className="row">
                                <div className="col-md-10 col-7">
                                  <div
                                    style={{
                                      marginBottom: "30px",
                                      color: "#4A4E50",
                                    }}
                                  >
                                    <p
                                      style={{
                                        fontSize: "15px",
                                        color: "#404040",
                                      }}
                                    >
                                      <b>{data.degree}</b>
                                    </p>
                                    <div
                                      style={{
                                        color: "#6E6E6E",
                                        fontSize: "15px",
                                        marginTop: "-8px",
                                      }}
                                    >
                                      <p>{data.school}</p>
                                      {data.specialization ? (
                                        <p style={{ marginTop: "-8px" }}>
                                          ({data.specialization})
                                        </p>
                                      ) : (
                                        ""
                                      )}
                                      <p style={{ marginTop: "-8px" }}>
                                        {data.startDate.slice(0, 4)} -{" "}
                                        {data.endDate.slice(0, 4)}
                                      </p>
                                      <p style={{ marginTop: "-8px" }}>
                                        {data.performanceScale}:{" "}
                                        {data.performance}%
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          );
                        })}
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
              <hr
                className="dropdown-divider1 mb-4 mt-3"
                style={{ height: "0.05px", color: "rgb(185, 185, 185)" }}
              />

              <div class="container" style={{ padding: "25px" }}>
                <div class="row">
                  <div class="col-3" style={{ minWidth: "150px" }}>
                    <p
                      style={{
                        color: "black",
                        fontSize: "22px",
                        letterSpacing: "0.5px",
                        fontWeight: "500",
                        lineHeight: "24px",
                      }}
                    >
                      WORK
                      <br /> EXPERIENCE
                      {resumeData &&
                        (resumeData?.resume?.Job ||
                          resumeData?.resume?.Internship) &&
                        calculateTotalExperience(
                          resumeData?.resume.Job,
                          resumeData?.resume.Internship
                        )}
                    </p>
                  </div>
                  <div class="col-9 mobile-column-to-increase-width">
                    {resumeData && resumeData?.resume.Job ? (
                      <>
                        {resumeData.resume.Job.map((data) => {
                          return (
                            <>
                              <div className="row">
                                {/* <div className="col-10"> */}
                                <div className="col-md-10 col-7">
                                  <div style={{ marginBottom: "30px" }}>
                                    <p
                                      style={{
                                        fontSize: "15px",
                                        color: "#404040",
                                      }}
                                    >
                                      <b>{data.profile} </b>
                                    </p>
                                    <div
                                      style={{
                                        color: "#6E6E6E",
                                        fontSize: "15px",
                                        marginTop: "-8px",
                                      }}
                                    >
                                      <p style={{ marginTop: "-8px" }}>
                                        {data.organization}, {data.location}
                                      </p>
                                      <p style={{ marginTop: "-8px" }}>
                                        Job{" "}
                                        <RxDotFilled
                                          style={{ color: "#464545" }}
                                        />{" "}
                                        {MONTH_DETAILS[
                                          parseInt(
                                            data.startDate.slice(5, 7),
                                            10
                                          )
                                        ] +
                                          " " +
                                          data.startDate.slice(0, 4)}{" "}
                                        -{" "}
                                        {MONTH_DETAILS[
                                          parseInt(data.endDate.slice(5, 7), 10)
                                        ] +
                                          " " +
                                          data.endDate.slice(0, 4)}{" "}
                                        {
                                          <MonthCalculator
                                            startDate={data.startDate}
                                            endDate={data.endDate}
                                          />
                                        }
                                      </p>
                                      {/* <p style={{ marginTop: "-8px" }}>
                                          {addEllipsis(
                                            data?.description,
                                            370
                                          )}
                                        </p> */}

                                      <SeeMoreLess
                                        text={data?.description}
                                        maxLength={350}
                                      />
                                    </div>
                                  </div>
                                </div>
                                {/* <div className="col-2" > */}
                              </div>
                            </>
                          );
                        })}
                      </>
                    ) : (
                      <></>
                    )}
                    {resumeData && resumeData?.resume.Internship ? (
                      <>
                        {resumeData?.resume.Internship.map((data) => {
                          return (
                            <>
                              <div className="row">
                                {/* <div className="col-10"> */}
                                <div className="col-md-10 col-7">
                                  <div
                                    style={{
                                      marginBottom: "30px",
                                      color: "#2C2C2C",
                                    }}
                                  >
                                    <p
                                      style={{
                                        fontSize: "15px",
                                        color: "#404040",
                                      }}
                                    >
                                      <b>{data.profile} </b>
                                    </p>
                                    <div
                                      style={{
                                        color: "#6E6E6E",
                                        fontSize: "15px",
                                        marginTop: "-8px",
                                      }}
                                    >
                                      <p style={{ marginTop: "-8px" }}>
                                        {data.organization}, {data.location}
                                      </p>
                                      <p style={{ marginTop: "-8px" }}>
                                        Internship{" "}
                                        <RxDotFilled
                                          style={{ color: "#464545" }}
                                        />{" "}
                                        {MONTH_DETAILS[
                                          parseInt(
                                            data.startDate.slice(5, 7),
                                            10
                                          )
                                        ] +
                                          " " +
                                          data.startDate.slice(0, 4)}{" "}
                                        -{" "}
                                        {MONTH_DETAILS[
                                          parseInt(data.endDate.slice(5, 7), 10)
                                        ] +
                                          " " +
                                          data.endDate.slice(0, 4)}{" "}
                                        {
                                          <MonthCalculator
                                            startDate={data.startDate}
                                            endDate={data.endDate}
                                          />
                                        }
                                      </p>
                                      <p style={{ marginTop: "-8px" }}>
                                        {data.description}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                {/* <div className="col-2" > */}
                              </div>
                            </>
                          );
                        })}
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>

              <hr
                className="dropdown-divider1 mb-4 mt-3"
                style={{ height: "0.05px", color: "rgb(185, 185, 185)" }}
              />
              {/* 
              <div class="container" style={{ padding: "25px" }}>
                <div class="row">
                  <div class="col-3" style={{ minWidth: "150px" }}>
                    <p
                      style={{
                        color: "black",
                        fontSize: "22px",
                        letterSpacing: "0.5px",
                        fontWeight: "500",
                        lineHeight: "24px",
                      }}
                    >
                      TRAININGS/
                      <br />
                      COURSES
                    </p>
                  </div>
                  <div class="col-9 mobile-column-to-increase-width">
                    {resumeData && resumeData?.resume.Training ? (
                      <>
                        {resumeData?.resume.Training.map((data) => {
                          return (
                            <>
                              <div className="row">
                                
                                <div className="col-md-10 col-7">
                                  <div style={{ marginBottom: "30px" }}>
                                    <p
                                      style={{
                                        fontSize: "15px",
                                        color: "#404040",
                                      }}
                                    >
                                      <b>{data.program}</b>
                                    </p>
                                    <div
                                      style={{
                                        color: "#6E6E6E",
                                        fontSize: "15px",
                                        marginTop: "-8px",
                                      }}
                                    >
                                      <p style={{ marginTop: "-8px" }}>
                                        {data.organization}, {data.location}
                                      </p>
                                      <p style={{ marginTop: "-8px" }}>
                                        {MONTH_DETAILS[
                                          parseInt(
                                            data.startDate.slice(5, 7),
                                            10
                                          )
                                        ] +
                                          " " +
                                          data.startDate.slice(0, 4)}{" "}
                                        -{" "}
                                        {MONTH_DETAILS[
                                          parseInt(data.endDate.slice(5, 7), 10)
                                        ] +
                                          " " +
                                          data.endDate.slice(0, 4)}
                                      </p>
                                      <SeeMoreLess
                                        text={data?.description}
                                        maxLength={350}
                                      />
                                    </div>
                                  </div>
                                </div>
                                
                              </div>
                            </>
                          );
                        })}
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>

              <hr
                className="dropdown-divider1 mb-4 mt-3"
                style={{ height: "0.05px", color: "rgb(185, 185, 185)" }}
              />

              <div class="container" style={{ padding: "25px" }}>
                <div class="row">
                  <div class="col-3" style={{ minWidth: "150px" }}>
                    <p
                      style={{
                        color: "black",
                        fontSize: "22px",
                        letterSpacing: "0.5px",
                        fontWeight: "500",
                        lineHeight: "24px",
                      }}
                    >
                      ACADEMICS/
                      <br /> PERSONAL PROJECTS
                    </p>
                  </div>
                  <div class="col-9 mobile-column-to-increase-width">
                    {resumeData && resumeData?.resume.Academics ? (
                      <>
                        {resumeData?.resume.Academics.map((data) => {
                          return (
                            <>
                              <div className="row">
                                
                                <div className="col-md-10 col-7">
                                  <div
                                    style={{
                                      marginBottom: "30px",
                                      color: "#2C2C2C",
                                    }}
                                  >
                                    <p
                                      style={{
                                        fontSize: "15px",
                                        color: "#404040",
                                      }}
                                    >
                                      <b>{data.title}</b>
                                    </p>
                                    <div
                                      style={{
                                        color: "#6E6E6E",
                                        fontSize: "15px",
                                        marginTop: "-8px",
                                      }}
                                    >
                                      <p style={{ marginTop: "-8px" }}>
                                        {MONTH_DETAILS[
                                          parseInt(
                                            data.startDate.slice(5, 7),
                                            10
                                          )
                                        ] +
                                          " " +
                                          data.startDate.slice(0, 4)}{" "}
                                        -{" "}
                                        {MONTH_DETAILS[
                                          parseInt(data.endDate.slice(5, 7), 10)
                                        ] +
                                          " " +
                                          data.endDate.slice(0, 4)}
                                      </p>
                                      <a
                                        target="_gray"
                                        href={data.link}
                                        style={{
                                          color: "#0885B7",
                                          display: "inline-block",
                                          maxWidth: "100%",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                          textDecoration: "none",
                                          whiteSpace: "nowrap",
                                        }}
                                      >
                                        {data.link}
                                      </a>
                                      <SeeMoreLess
                                        text={data?.description}
                                        maxLength={350}
                                      />
                                    </div>
                                  </div>
                                </div>
                                
                              </div>
                            </>
                          );
                        })}
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>

              <hr
                className="dropdown-divider1 mb-4 mt-3"
                style={{ height: "0.05px", color: "rgb(185, 185, 185)" }}
              /> */}

              <div class="container" style={{ padding: "25px" }}>
                <div class="row">
                  <div class="col-3" style={{ minWidth: "200px" }}>
                    <p
                      style={{
                        color: "black",
                        fontSize: "22px",
                        letterSpacing: "0.5px",
                        fontWeight: "500",
                      }}
                    >
                      SKILLS
                    </p>
                  </div>
                  <div class="col-9">
                    {resumeData && resumeData?.resume.Skills ? (
                      <>
                        <div className="row">
                          {resumeData?.resume.Skills.map((data) => {
                            return (
                              <>
                                <div
                                  className="col-6"
                                  style={{ minWidth: "250px" }}
                                >
                                  <div className="row">
                                    <div
                                      className="col-7"
                                      style={{
                                        marginBottom: "10px",
                                        fontSize: "17px",
                                      }}
                                    >
                                      <p
                                        style={{
                                          fontSize: "15px",
                                          color: "#404040",
                                        }}
                                      >
                                        <b>{data.name}</b>
                                      </p>
                                      <div
                                        style={{
                                          color: "black",
                                          marginTop: "-8px",
                                          marginRight: "30px",
                                        }}
                                      >
                                        <div
                                          style={{
                                            color: "#6E6E6E",
                                            fontSize: "15px",
                                            marginTop: "-8px",
                                          }}
                                        >
                                          <p style={{ marginTop: "10px" }}>
                                            {data.level}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            );
                          })}
                        </div>{" "}
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="col-1"></div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              maxWidth: "940px",
              margin: "auto",
            }}
          >
            <button
              className="shortcut-newappliedjob"
              // onClick={() => handleChatClick(state2?.user?._id, state2?.employer, state2?.job?._id, state2?._id , navigate)}
              // onClick={() => setOpenPopup(!openPopup)}
              onClick={ChatToUser}
            >
              Message
            </button>
          </div>
        </div>
      ) : (
        <div>Loading preview...</div>
      )}
    </>
  );
};

export default PreviewCandidate;
