import React, { useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { PiDotsSixVerticalBold } from "react-icons/pi";
import { GoArrowDown } from "react-icons/go";
import { GoArrowUp } from "react-icons/go";
import CloseButton from "./CloseButton";

const ReorderModal = ({ projects, onSave, onClose }) => {
  const [orderedProjects, setOrderedProjects] = useState(projects);

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(orderedProjects);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setOrderedProjects(items);
  };
  const moveItem = (index, direction) => {
    const newOrder = [...orderedProjects];
    const [movedItem] = newOrder.splice(index, 1);
    newOrder.splice(index + direction, 0, movedItem);
    setOrderedProjects(newOrder);
  };

  return (
    <>
      <div className="modal-backdrop fade show"></div>
      <div className="modal fade show d-block" tabIndex="-1">
        <div className="modal-dialog modal-dialog-centered">
          <div
            className="modal-content px-3 py-3 "
            style={{ borderRadius: "15px", marginTop: "5px", height: "80%" }}
          >
            <div
              className=""
              style={{
                paddingBottom: "-10px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  color: "#444343",
                  fontSize: "27px",
                  fontWeight: "500",
                  textAlign: "left",
                  alignItems: "start",
                }}
              >
                Reorder Portfolio Projects
              </p>
              <CloseButton onClick={onClose} />
              {/* <div>
                <button
                  type="button"
                  class="btn-close"
                  onClick={onClose}
                ></button>
              </div> */}
            </div>
            <div
              className="modal-body"
              style={{
                overflowY: "scroll",
                height: "75vh",
                // backgroundColor: "red",
              }}
            >
              <div className="step-content container ">
                {/* <div style={{ textAlign: "end" }}>
                                
                                <button type="button" class="btn-close" onClick={handleClose}></button>
                            </div> */}

                <DragDropContext onDragEnd={handleDragEnd}>
                  <Droppable droppableId="projects">
                    {(provided) => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        {orderedProjects.map((project, index) => (
                          <Draggable
                            key={project._id}
                            draggableId={project._id}
                            index={index}
                          >
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className="card mb-2"
                                // style={{backgroundColor:'#f9f9f9', borderRadius:'15px', padding:'8px',}}
                              >
                                <div
                                  className="card-body d-flex align-items-center"
                                  style={{
                                    backgroundColor: "#f9f9f9",
                                    borderRadius: "15px",
                                    padding: "8px",
                                  }}
                                >
                                  <PiDotsSixVerticalBold
                                    style={{
                                      fontSize: "41px",
                                      color: "black",
                                      marginRight: "15px",
                                    }}
                                  />
                                  <img
                                    src={project.selectedThumbnail.url}
                                    alt={project.projectTitle}
                                    className="me-3"
                                    style={{
                                      width: "100px",
                                      height: "98px",
                                      borderRadius: "15px",
                                      objectFit: "cover",
                                    }}
                                  />

                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      width: "100%",
                                    }}
                                  >
                                    <span className="dragableportfoliosize">
                                      {project.projectTitle}
                                    </span>
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <GoArrowUp
                                        style={{
                                          fontSize: "30px",
                                          color:
                                            index === 0
                                              ? "darkgray"
                                              : "#2092c7",
                                          cursor:
                                            index === 0
                                              ? "not-allowed"
                                              : "pointer",
                                        }}
                                        onClick={() =>
                                          index > 0 && moveItem(index, -1)
                                        }
                                      />
                                      <GoArrowDown
                                        style={{
                                          fontSize: "30px",
                                          color:
                                            index === orderedProjects.length - 1
                                              ? "darkgray"
                                              : "#2092c7",
                                          cursor:
                                            index === orderedProjects.length - 1
                                              ? "not-allowed"
                                              : "pointer",
                                        }}
                                        onClick={() =>
                                          index < orderedProjects.length - 1 &&
                                          moveItem(index, 1)
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                style={{
                  width: "74px",
                  maxWidth: "74px",
                  padding: "5px",
                  border: "2px solid #2092c7",
                  borderRadius: "15px",
                  color: "#2092c7",
                  backgroundColor: "white",
                  fontSize: "16px",
                  marginRight: "10px",
                }}
                onClick={onClose}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary"
                style={{
                  width: "74px",
                  maxWidth: "74px",
                  padding: "5px",
                  border: "none",
                  borderRadius: "15px",
                  backgroundColor: "#2092c7",
                  color: "white",
                  fontSize: "16px",
                }}
                onClick={() => onSave(orderedProjects)}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReorderModal;
