import React, { useState, useEffect, useRef } from "react";
import "./css/LandingPage.scss";
import "./css/job-intern.scss";
import Card from "./Card";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const categories = [
  "All",
  "Hybrid",
  "Remote",
  "Part-time",
  "Android ",
  "Full Stack",
  "Java",
  "C++",
  "React",
];

export default function Jobs({ data }) {
  const [currentCardIndex, setCurrentCardIndex] = useState(0);

  const [settings, setSettings] = useState({
    speed: 500,
    slidesToScroll: 1,
    // infinite: true,
    infinite: false,
    slidesToShow: 4, // Default value, can be overridden
    beforeChange: (oldIndex, newIndex) => handleBeforeChange(newIndex),
    afterChange: (index) => handleAfterChange(index),
  });

  useEffect(() => {
    // Function to update slidesToShow based on window size
    const updateSlidesToShow = () => {
      const pageSize = window.innerWidth;
      let slidesToShow, slidesToScroll;
      if (pageSize >= 1300) {
        slidesToShow = 4; // Show 4 slides for large screens
        slidesToScroll = 4;
      } else if (pageSize >= 992) {
        slidesToShow = 3; // Show 3 slides for medium screens
        slidesToScroll = 3;
      } else if (pageSize >= 768) {
        slidesToShow = 2; // Show 2 slides for small screens
        slidesToScroll = 2;
      } else {
        slidesToShow = 1; // Show 1 slide for extra small screens
        slidesToScroll = 1;
      }
      // Update the state with the new value of slidesToShow
      setSettings((prevSettings) => ({
        ...prevSettings,
        slidesToShow,
        slidesToScroll,
      }));
    };

    // Call the function initially to set the correct value
    updateSlidesToShow();

    // Listen to the window resize event to update the slidesToShow value dynamically
    window.addEventListener("resize", updateSlidesToShow);

    // Clean up the event listener
    return () => {
      window.removeEventListener("resize", updateSlidesToShow);
    };
  }, []); // Empty dependency array ensures that this effect runs only once on mount
  const handleBeforeChange = (newIndex) => {
    setCurrentCardIndex(newIndex);
  };

  const handleAfterChange = (index) => {
    setCurrentCardIndex(index);
    updateThumbPosition(index);
  };
  const thumbRef = useRef(null);
  const [activeCategory, setActiveCategory] = useState("All"); // Initial active category

  const handleCategoryClick = (category) => {
    setActiveCategory(category);
    setCurrentCardIndex(0);
  };

  //   const filterData = (category) => {
  //     if (category === 'All') {
  //         return data;
  //     }
  //     else if(category === 'Hybrid'){
  //       return data;
  //     }
  //     return data.filter(
  //         (item) =>
  //             item.jobType === category ||
  //             (category === 'Part-time' && item.isPartTimeAllowed === 'Yes') ||
  //             // item.skill.includes(category) ||
  //             item.skill.some(skill => skill.toLowerCase().includes(category.toLowerCase())) ||
  //             (item.profile.toLowerCase().includes(category.toLowerCase())) // New condition for searching on profile
  //     );
  // };
  const filterData = (category) => {
    let filteredData;
    if (category === "All" || category === "Hybrid") {
      filteredData = data;
    } else {
      filteredData = data.filter(
        (item) =>
          item.jobType === category ||
          (category === "Part-time" && item.isPartTimeAllowed === "Yes") ||
          item.skill.some((skill) =>
            skill.toLowerCase().includes(category.toLowerCase())
          ) ||
          item.profile.toLowerCase().includes(category.toLowerCase())
      );
    }
    return { filteredData, length: filteredData.length };
  };
  const { filteredData, length } = filterData(activeCategory);

  const sliderRef = useRef(null);
  const slider = useRef(null);

  // const handlePrevButtonClick = () => {
  //   // const newIndex = Math.max(currentCardIndex - 1, 0);
  //   const newIndex = Math.max(currentCardIndex - settings.slidesToScroll, 0);
  //   setCurrentCardIndex(newIndex);
  //   slider?.current?.slickPrev();
  // };

  // const handleNextButtonClick = () => {
  //   const newIndex = Math.min(currentCardIndex + 1, data.length - 1);
  //   setCurrentCardIndex(newIndex);
  //   slider?.current?.slickNext();
  // };
  // const handleNextButtonClick = () => {
  //   const newIndex = currentCardIndex + 1;
  //   const totalItems = filterData(activeCategory).length;
  //   const visibleItems = settings.slidesToShow;

  //   // Check if there's room to slide
  //   if (newIndex <= totalItems - visibleItems) {
  //     setCurrentCardIndex(newIndex);
  //     slider?.current?.slickNext();
  //   } else if (newIndex < totalItems) {
  //     // If there are items left but not enough to fill the slider, adjust current index
  //     setCurrentCardIndex(totalItems - visibleItems);
  //   }
  // };
  // const handleNextButtonClick = () => {
  //   const newIndex = currentCardIndex + 1;
  //   const totalItems = filterData(activeCategory).length;
  //   const visibleItems = settings.slidesToShow;

  //   // Calculate the maximum index to prevent going beyond the available items
  //   const maxIndex = Math.max(totalItems - visibleItems, 0);

  //   // If newIndex exceeds maxIndex, clamp it to maxIndex
  //   const clampedIndex = Math.min(newIndex, maxIndex);

  //   // Check if there's room to slide
  //   if (newIndex <= maxIndex) {
  //     setCurrentCardIndex(clampedIndex);
  //     slider?.current?.slickNext();
  //   }
  // };
  // const handleNextButtonClick = () => {
  //   const totalItems = filterData(activeCategory).length;
  //   const visibleItems = settings.slidesToShow;
  //   const newIndex = currentCardIndex + settings.slidesToScroll;

  //   // Adjust newIndex to handle the case where remaining items are less than slidesToShow
  //   if (newIndex + visibleItems > totalItems) {
  //     setCurrentCardIndex(Math.max(totalItems - visibleItems, 0));
  //   } else {
  //     setCurrentCardIndex(newIndex);
  //   }

  //   slider?.current?.slickNext();
  // };

  // const updateThumbPosition = () => {
  //   const totalItems = data.length;
  //   // const visibleItems = 4;
  //   const visibleItems = settings.slidesToShow;
  //   const maxThumbLeft = 63;
  //   const thumbLeft = Math.min(
  //     (currentCardIndex / (totalItems - visibleItems)) * maxThumbLeft,
  //     maxThumbLeft
  //   );
  //   thumbRef.current.style.left = `${thumbLeft}%`;
  // };

  const handlePrevButtonClick = () => {
    slider.current.slickPrev();
  };

  const handleNextButtonClick = () => {
    const totalItems = filterData(activeCategory).length;
    const visibleItems = settings.slidesToShow;
    const newIndex = currentCardIndex + settings.slidesToScroll;

    // Adjust newIndex to handle the case where remaining items are less than slidesToShow
    if (newIndex + visibleItems > totalItems) {
      setCurrentCardIndex(Math.max(totalItems - visibleItems, 0));
    } else {
      setCurrentCardIndex(newIndex);
    }

    slider.current.slickNext();
  };

  const updateThumbPosition = (index) => {
    const totalItems = data.length;
    const visibleItems = settings.slidesToShow;
    const maxThumbLeft = 63;
    const thumbLeft = Math.min(
      (index / (totalItems - visibleItems)) * maxThumbLeft,
      maxThumbLeft
    );
    thumbRef.current.style.left = `${thumbLeft}%`;
  };

  useEffect(() => {
    updateThumbPosition();
  }, [currentCardIndex]);

  useEffect(() => {
    console.log(filterData(activeCategory));
  }, [activeCategory]);

  return (
    <div
      className="internships-jobs-section"
      style={{ background: "#FAFAFA", padding: "72px 0 0" }}
    >
      <div className="title">
        <h5 style={{ fontSize: "25px", textAlign: "center" }}>
          Latest Hourly Projects on Workcroft
        </h5>
        <span className="diffTitle">Popular categories</span>
        <div className="popular-list">
          <span>Popular categories:</span>
          <ul>
            {categories.map((item, index) => (
              <li
                onClick={() => handleCategoryClick(item)}
                className={activeCategory === item ? "active" : ""}
                key={index}
              >
                <div href="/">{item}</div>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {/* <div>
          <div className="scroll" style={{maxWidth: "85vw"}}>
          <Slider ref={slider} {...settings}>
         {filterData(activeCategory).map((item, index) => (
        <Card key={index} props={item} />
        ))}
         </Slider>

          </div>
        </div> */}
        {/* {(filterData(activeCategory).length ) >= settings.slidesToShow  && (filterData(activeCategory).length !== 1)? ( // Check if enough items to display in Slider
        <div>
          <div className="scroll" style={{ maxWidth: "85vw" }}>
            <Slider ref={slider} {...settings}>
              {filterData(activeCategory).map((item, index) => (
                <Card key={index} props={item} isFilter={activeCategory !== 'All'}/>
              ))}
            </Slider>
          </div>
        </div>
      ) : (
        <div className="scroll flex" style={{ maxWidth: "85vw" ,display:'flex'}}>
          {filterData(activeCategory).map((item, index) => (
            <Card key={index} props={item} isFilter={activeCategory !== 'All'}/>
          ))}
        </div>
      )} */}
        {length >= settings.slidesToShow && length !== 1 ? (
          <div>
            <div className="scroll" style={{ maxWidth: "85vw" }}>
              <Slider ref={slider} {...settings}>
                {filteredData.map((item, index) => (
                  <Card
                    key={index}
                    props={item}
                    isFilter={activeCategory !== "All"}
                    dataLength={length}
                  />
                ))}
              </Slider>
            </div>
          </div>
        ) : (
          <div
            className="scroll flex"
            style={{ maxWidth: "85vw", display: "flex" }}
          >
            {filteredData.map((item, index) => (
              <Card
                key={index}
                props={item}
                isFilter={activeCategory !== "All"}
                dataLength={length}
              />
            ))}
          </div>
        )}

        <div
          className="scroll-buttons"
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: "32px",
          }}
        >
          <button
            onClick={handlePrevButtonClick}
            ref={sliderRef}
            disabled={currentCardIndex === 0}
            className="arrow"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-chevron-left"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"
              />
            </svg>
          </button>

          <div className="track">
            <div
              className="thumb"
              ref={thumbRef}
              style={{ width: "37.046%" }}
            ></div>
          </div>

          <button
            onClick={handleNextButtonClick}
            ref={sliderRef}
            // disabled={currentCardIndex >= data.length - 4}
            disabled={currentCardIndex >= data.length - settings.slidesToShow}
            className="arrow"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-chevron-right"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
}
