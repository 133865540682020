import React, { useEffect, useState } from "react";
import "./EmployerNew/css/postInternship.css";
import axios from "axios";
import Select from "react-select";

const EditSkillsSelector = ({ handleSelect, isOptional, data }) => {
  // console.log(data, "pdSkills");
  const [allskills, setAllSkills] = useState([]);
  const [selectedSkill, setSelectedSkill] = useState([]);
  const [isTyping, setIsTyping] = useState(false);
  const [searchTerm2, setSearchTerm2] = useState("");

  useEffect(() => {
    setSelectedSkill(data);
  }, []);

  //console.log(selectedSkill, data, "selected Skills");

  const handleInputChange2 = (newValue) => {
    setSearchTerm2(newValue);
    setIsTyping(newValue.length > 0);
  };
  const handleSkillChange = (selectedOptions) => {
    setSelectedSkill([...selectedSkill, selectedOptions]);
    handleSelect(selectedOptions);
    // setJob({
    //   ...job,
    //   skill: selectedOptions.map((option) => option.value),
    // });
  };

  useEffect(() => {
    async function fetchAllSkills() {
      if (searchTerm2.trim()) {
        try {
          const response = await axios.get(`/api/data/skills`, {
            params: { keyword: searchTerm2 },
          });
          if (Array.isArray(response.data)) {
            let res = response.data.map((skill) => ({
              label: skill,
              value: skill,
            }));
            setAllSkills([...res]);
          } else {
            console.error("Unexpected response format:", response.data);
          }
        } catch (error) {
          console.error("Error fetching skills:", error);
        }
      } else {
        setAllSkills([]);
      }
    }
    fetchAllSkills();
  }, [searchTerm2]);

  return (
    <div className="details">
      <Select
        placeholder="List the key skills and deliverables"
        className="select-tag"
        isMulti
        value={data}
        onChange={handleSkillChange}
        options={allskills}
        onInputChange={handleInputChange2}
        menuPortalTarget={document.body}
        styles={{
          menuPortal: (base) => ({
            ...base,
            zIndex: 9999,
          }),
        }}
        // noOptionsMessage={noOptionsMessage2}
        menuIsOpen={isTyping}
      />
    </div>
  );
};

export default EditSkillsSelector;
