// src/ModalComponent.js
import React, { useState } from "react";
import AvatarEditor from "react-avatar-editor";
import CloseButton from "../Student_New/CloseButton";

const PhotoEditorModal = ({ show, handleClose, handleSave, editorData }) => {
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [position, setPosition] = useState({ x: 0.5, y: 0.5 });
  const [imageWidth, setImageWidth] = useState(200);
  const [imageHeight, setImageHeight] = useState(200);
  let editor = null;

  const saveEditedPhoto = async () => {
    if (editor) {
      const canvas = editor.getImageScaledToCanvas();
      const ctx = canvas.getContext("2d");

      // Resize the canvas to a fixed size (e.g., 150x150)
      // const maxWidth = 150;
      // const maxHeight = 150;
      const maxWidth = 521;
      const maxHeight = 352;

      let width = canvas.width;
      let height = canvas.height;
      //console.log(width, height, "canvasimage");
      if (width > height) {
        if (width > maxWidth) {
          height = Math.round((height *= maxWidth / width));
          width = maxWidth;
        }
      } else {
        if (height > maxHeight) {
          width = Math.round((width *= maxHeight / height));
          height = maxHeight;
        }
      }

      const resizedCanvas = document.createElement("canvas");
      resizedCanvas.width = width;
      resizedCanvas.height = height;
      const resizedCtx = resizedCanvas.getContext("2d");
      resizedCtx.drawImage(canvas, 0, 0, width, height);

      // const format = 'image/png'; // You can adjust the format and quality here
      const format = "image/jpeg";
      const resizedDataUrl = resizedCanvas.toDataURL(format, 0.95); // Adjust quality if needed

      //   setUserImage(resizedDataUrl);
      //   setAvatar(resizedDataUrl);
      //   setIsEditingPhoto(false);
      handleSave(resizedDataUrl);
      handleClose(false);
    }
  };
  const handlePositionChange = (position) => {
    setPosition(position);
  };

  return (
    <>
      <div
        className={`modal fade ${show ? "show d-block" : ""}`}
        tabIndex="-1"
        role="dialog"
        style={{ display: show ? "block" : "none" }}
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Edit Photo</h5>
              <CloseButton
                className="btn-close"
                onClick={() => handleClose(false)}
              />
            </div>
            <div className="modal-body text-center">
              <AvatarEditor
                ref={(ref) => (editor = ref)}
                crossOrigin="anonymous"
                image={editorData}
                // image={personalDetails.photo?.url || personalDetails.photo}
                // width={200}
                // height={200}
                width={imageWidth}
                height={imageHeight}
                border={10}
                color={[218, 230, 242, 0.45]}
                scale={scale}
                rotate={rotate}
                position={position}
                onPositionChange={handlePositionChange}
              />
              <div className="mt-3">
                <label>Zoom: </label>
                <input
                  type="range"
                  min="1"
                  max="3"
                  step="0.1"
                  value={scale}
                  onChange={(e) => setScale(parseFloat(e.target.value))}
                />
              </div>
              <div className="mt-2">
                <label>Rotate: </label>
                <input
                  type="range"
                  min="0"
                  max="360"
                  step="1"
                  value={rotate}
                  onChange={(e) => setRotate(parseFloat(e.target.value))}
                />
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                style={{
                  fontSize: "17px",
                  paddingLeft: "6px",
                  paddingRight: "6px",
                }}
                onClick={() => handleClose(false)}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={saveEditedPhoto}
                style={{
                  fontSize: "17px",
                  paddingLeft: "6px",
                  paddingRight: "6px",
                }}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
      {show && <div className="modal-backdrop fade show"></div>}
    </>
  );
};

export default PhotoEditorModal;
