import React, {useEffect, useState, useRef } from 'react';
import Slider from 'react-slick';
import './LogoSlider.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

const LogoSlider = ({ data }) => {
  const sliderRef = useRef(null);
  const [currentCardIndex, setCurrentCardIndex] = useState(0);

  const handlePrevClick = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev(); // Call slick's previous slide function
    }
  };

  const handleNextClick = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext(); // Call slick's next slide function
    }
  };

  const [settings, setSettings] = useState({
    speed: 500,
    slidesToScroll: 1,
    infinite: false,
    slidesToShow: 7, // Default value, can be overridden 
    beforeChange: (oldIndex, newIndex) => handleBeforeChange(newIndex),
    afterChange: index => handleAfterChange(index),
  });

  useEffect(() => {
    // Function to update slidesToShow based on window size
    const updateSlidesToShow = () => {
      const pageSize = window.innerWidth;
      let slidesToShow,slidesToScroll;
      if (pageSize >= 1300) {
        slidesToShow = 8; // Show 7 slides for large screens
        slidesToScroll=8;
      } else if (pageSize >= 1100) {
        slidesToShow = 6; // Show 5 slides for medium screens
        slidesToScroll=6;
      } else if (pageSize >= 992) {
        slidesToShow = 5; // Show 5 slides for medium screens
        slidesToScroll=5;
      } else if (pageSize >= 850) {
        slidesToShow = 4; // Show 5 slides for medium screens
        slidesToScroll=4;
      } else if (pageSize >= 668) {
        slidesToShow = 3; // Show 3 slides for small screens
        slidesToScroll=3;
      } else {
        slidesToShow = 2; // Show 1 slide for extra small screens
        slidesToScroll=2;
      }
      // Update the state with the new value of slidesToShow
      setSettings(prevSettings => ({
        ...prevSettings,
        slidesToShow,
        slidesToScroll
      }));
    };

    // Call the function initially to set the correct value
    updateSlidesToShow();

    // Listen to the window resize event to update the slidesToShow value dynamically
    window.addEventListener('resize', updateSlidesToShow);

    // Clean up the event listener
    return () => {
      window.removeEventListener('resize', updateSlidesToShow);
    };
  }, []); // Empty dependency array ensures that this effect runs only once on mount



  // const handlePrevButtonClick = () => {
  //   const newIndex = Math.max(currentCardIndex - 1, 0);
  //   setCurrentCardIndex(newIndex);
  //   slider?.current?.slickPrev();
  // };

  // const handleNextButtonClick = () => {
  //   const newIndex = Math.min(currentCardIndex + 1, data.length - 1);
  //   setCurrentCardIndex(newIndex);
  //   slider?.current?.slickNext();
  // };

  const handleBeforeChange = (newIndex) => {
    setCurrentCardIndex(newIndex);
  };

  const handleAfterChange = (index) => {
    setCurrentCardIndex(index);
    updateThumbPosition(index);
  };

  // const updateThumbPosition = () => {
  //   const totalItems = data.length;
  //   const visibleItems = 7;
  //   const maxThumbLeft = 63;
  //   const thumbLeft = Math.min(
  //     (currentCardIndex / (totalItems - visibleItems)) * maxThumbLeft,
  //     maxThumbLeft
  //   );
  //   thumbRef.current.style.left = `${thumbLeft}%`;
  // };
  const handlePrevButtonClick = () => {
    slider.current.slickPrev();
  };

  const handleNextButtonClick = () => {
    slider.current.slickNext();
  };

  const updateThumbPosition = (index) => {
    const totalItems = data.length;
    const visibleItems = settings.slidesToShow;
    const maxThumbLeft = 63;
    const thumbLeft = Math.min(
      (index / (totalItems - visibleItems)) * maxThumbLeft,
      maxThumbLeft
    );
    thumbRef.current.style.left = `${thumbLeft}%`;
  };

  useEffect(() => {
    updateThumbPosition();
  }, [currentCardIndex]);


  const slider = useRef(null);
  const thumbRef = useRef(null);
  return (
    <div className="internships-jobs-section" style={{ background: '#F0FBFF', padding: '20px 0' }}>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <div className="scroll" style={{maxWidth: "85vw"}}>
          <Slider {...settings} ref={slider}>
            {data?.map((item, index) => (
              <div className="mr-4 my-5" key={index}>
                {/* <img src={item} alt="logo" style={{ height: "100px", width: "90%" }} /> */}
                <img
                  src={item}
                  alt="logo"
                  style={{
                    display:"flex",
                    // height: "100px",
                    height: (item === "https://res.cloudinary.com/dlngy1xko/image/upload/v1709035740/blackcoffer/uj2ddcsj6jctc0rfvqou.png" ? "56px":
                    item === "https://res.cloudinary.com/dlngy1xko/image/upload/v1718449471/blackcoffer/t3286vnjzdcingfejovz.png" ?"56px":
                    item === "https://res.cloudinary.com/dlngy1xko/image/upload/v1710414956/blackcoffer/lqzztkoskgfo6wkxs6pg.jpg"?"67px":
                    item === "https://res.cloudinary.com/dlngy1xko/image/upload/v1710238400/blackcoffer/c2bospuchzrbuxz0h03c.png"? "55px":
                    item === "https://res.cloudinary.com/dlngy1xko/image/upload/v1716370760/blackcoffer/a2dmucmtviit3n51s0h9.png"? "43px":
                    "75px"),
                    // width: item === "https://res.cloudinary.com/dlngy1xko/image/upload/v1710238400/blackcoffer/c2bospuchzrbuxz0h03c.png" ? "75%" : "90%"
                    width: item === "https://res.cloudinary.com/dlngy1xko/image/upload/v1710238400/blackcoffer/c2bospuchzrbuxz0h03c.png" ? "32%": 
                item === "https://res.cloudinary.com/dlngy1xko/image/upload/v1710414956/blackcoffer/lqzztkoskgfo6wkxs6pg.jpg" ? "56%" :
                item === "https://res.cloudinary.com/dlngy1xko/image/upload/v1709035740/blackcoffer/uj2ddcsj6jctc0rfvqou.png" 
                ? "52%" :
                item=== "https://res.cloudinary.com/dlngy1xko/image/upload/v1718449471/blackcoffer/t3286vnjzdcingfejovz.png"?
                '52%': item === "https://res.cloudinary.com/dlngy1xko/image/upload/v1716370760/blackcoffer/a2dmucmtviit3n51s0h9.png" ? '50%':
                "125px" ,
                marginLeft:
      item === "https://res.cloudinary.com/dlngy1xko/image/upload/v1710238400/blackcoffer/c2bospuchzrbuxz0h03c.png"
        ? "60px"
        : item === "https://res.cloudinary.com/dlngy1xko/image/upload/v1710414956/blackcoffer/lqzztkoskgfo6wkxs6pg.jpg"
        ? "26px"
        : item === "https://res.cloudinary.com/dlngy1xko/image/upload/v1709035740/blackcoffer/uj2ddcsj6jctc0rfvqou.png"
        ? "30px" : item === "https://res.cloudinary.com/dlngy1xko/image/upload/v1718449471/blackcoffer/t3286vnjzdcingfejovz.png" ?'30px'
        : "0px",
        marginTop: item === "https://res.cloudinary.com/dlngy1xko/image/upload/v1716370760/blackcoffer/a2dmucmtviit3n51s0h9.png" && '19px'
                  }}
                />
              </div>
            ))}
          </Slider>
        </div>

        <div
          className="scroll-buttons"
          style={{
            display: 'flex',
            alignItems: 'center',
            marginTop: '-10px',
          }}
        >
          <button
            onClick={handlePrevButtonClick}
            ref={sliderRef}
            disabled={currentCardIndex === 0}
            className="arrow"
            style={{width: "30px", height: "30px"}}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              fill="currentColor"
              class="bi bi-chevron-left"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"
              />
            </svg>
          </button>

          <div className="track" style= {{height: "3px"}}>
            <div
              className="thumb"
              ref={thumbRef}
              style={{ width: '37.046%'}}
            ></div>
        </div>

          <button
            onClick={handleNextButtonClick}
            ref={sliderRef}
            // disabled={currentCardIndex >= data.length - settings.slidesToShow}
            disabled={currentCardIndex >= data.length - settings.slidesToScroll}
            className="arrow"
            style={{width: "30px", height: "30px"}}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              fill="currentColor"
              class="bi bi-chevron-right"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"
              />
            </svg>
          </button>
        </div>

        
      </div>
    </div>
  );
}


export default LogoSlider;
