import React, { useState, useEffect } from "react";
import "./UserApplications.scss";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import PropagateLoader from "react-spinners/PropagateLoader";
import { MONTH_DETAILS } from "../data";
import { CgFileDocument } from "react-icons/cg";
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";
import "./Jobs.scss";
import { useDispatch } from "react-redux";
import { loadUser } from "../../actions/userActions";

function UserApplications() {
  const openPreviewPageInNewTab = (state) => {
    const data = state;
    window.open(
      `/preview/${data.profile.replace(/[/?$%@&]/g, "-").replace(/ /g, "-")}-${
        data.opportunityType
      }-at-${data.organisationName.replace(/ /g, "-")}_${
        data._id
      }`.toLowerCase()
    );
  };

  const openReviewPageInNewTab = (state) => {
    window.open(`/review/${state.application._id}`);
  };

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo(0, 0);
  }, []);

  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [userApplicationData, setUserApplicationData] = useState("");
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const getUserData = async (page) => {
    setLoading(true);
    try {
      const { data } = await axios.post(
        "/api/user/myApplyJobs",
        { page },
        {
          headers: {
            "Content-Type": "application/json",
            token: localStorage.getItem("token"),
          },
        }
      );
      setUserApplicationData(data.jobs);
      setTotalPage(data.totalPages);
      setLoading(false);
    } catch (error) {
      console.log(error.response.data);
      setLoading(false);
    }
  };

  useEffect(() => {
    getUserData(page);
    window.scrollTo(0, 0);
  }, [page]);

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPage) {
      setPage(newPage);
    }
  };
  useEffect(() => {
    dispatch(loadUser());
  }, [dispatch]);

  // console.log(userApplicationData, "userApplicationData");

  return (
    <div className="my-applications">
      {loading ? (
        <>
          <div className="my-auto" style={{ textAlign: "center" }}>
            <p
              style={{
                color: "#4B92C8",
                fontWeight: "500",
                fontSize: "20px",
                marginLeft: "43px",
                marginBottom: "5px",
              }}
            >
              Loading..
            </p>
            <PropagateLoader
              color={"#4B92C8"}
              loading={true}
              size={15}
              aria-label="Loading Spinner"
              data-testid="loader"
              style={{ marginLeft: "27px" }}
            />
          </div>
        </>
      ) : (
        <>
          {userApplicationData.length !== 0 ? (
            <>
              <h3 className="title">My applications</h3>

              <div className="table-box table-responsive border">
                <table className="applications table">
                  <thead>
                    <tr>
                      <th style={{ textAlign:"center" }}>Project Type</th>
                      <th style={{ textAlign:"center"}}>
                        <div>Project</div> Title
                      </th>
                      <th
                        className="usertableheadingstyle"
                        style={{ textAlign: "center" }}
                      >
                        APPLIED ON
                      </th>
                      <th className="usertableheadingstyle">
                        NUMBER OF <br />
                        APPLICANTS
                      </th>
                      <th>APPLICATION STATUS</th>
                      {/* <th>MESSAGE</th> */}
                      <th>
                        REVIEW <br /> APPLICATION
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {userApplicationData.map((application, index) => (
                      <tr key={index}>
                        <td style={{ fontSize: "15px", paddingInline: "1rem",textAlign:"center" }}>
                          {application?.job?.opportunityType}{" "}
                        </td>
                        <td
                          style={{
                            fontSize: "15px",
                            textAlign: "start",
                            paddingLeft: "48px",
                          }}
                          className="usertableheadingstyle"
                        >
                          {application?.job?.profile}
                          <i
                            class="fa fa-external-link  previewIcon"
                            aria-hidden="true"
                            onClick={() =>
                              openPreviewPageInNewTab(application.job)
                            }
                          ></i>
                        </td>
                        <td
                          style={{ fontSize: "15px", textAlign: "center" }}
                          className="usertableheadingstyle"
                        >
                          {(application?.appliedDate).slice(8, 10) +
                            " " +
                            MONTH_DETAILS[
                              parseInt(application?.appliedDate.slice(5, 7), 10)
                            ] +
                            "' " +
                            application.appliedDate.slice(2, 4)}
                        </td>
                        <td style={{ fontSize: "15px", textAlign: "center" }}>
                          {application?.job?.userApplied?.length}
                        </td>
                        <td style={{ fontSize: "15px", textAlign: "center" }}>
                          <p
                            style={{
                              background: "#EAF8FE",
                              color: "#2880E4",
                              fontWeight: "500",
                              fontSize: "14px",
                              letterSpacing: "0.6px",
                              padding: "4px 40px",
                              borderRadius: "20px",
                              display: "inline",
                            }}
                          >
                            {application &&
                            application?.status === "Application_Sent" ? (
                              <>Applied</>
                            ) : (
                              <>
                                {application?.status === "NotIntrested" ? (
                                  <>Not Intrested</>
                                ) : (
                                  <>
                                    {application?.status === "Assignment" ? (
                                      <>Assignment Sent</>
                                    ) : (
                                      <>
                                        {application?.status ===
                                        "Interviews" ? (
                                          <>Interview Scheduled</>
                                        ) : (
                                          <>{application?.status}</>
                                        )}
                                      </>
                                    )}
                                  </>
                                )}
                              </>
                            )}
                          </p>
                        </td>
                        {/* <td><img onClick={() => navigate('/messageStudent', { state: application })} src={chat} alt="chat" style={{ cursor: "pointer", height: "28px", width: "auto", marginBottom: "-5px" }} /></td>*/}
                        <td style={{ fontSize: "15px", textAlign: "center" }}>
                          <CgFileDocument
                            className="docIcon"
                            onClick={() =>
                              openReviewPageInNewTab({ application })
                            }
                          />
                        </td>

                        {/* <td style={{fontSize: "15px"}}><button className={`btn viewed status`}>Under Review</button></td>
                    <td></td>
                    <td></td>
                    <td style={{fontSize: "15px"}}><img onClick={() => navigate('/review', { state: application })} src={list} alt="Account" style={{ cursor: "pointer" }} /></td>

                     <td> */}
                        {/* <div>
                  <AiFillCrown style={{ marginRight: '5px', fontSize: '1.5em',marginTop:'-5px',color:'#F1BC00'}} />
                    UPGRADE</div> */}
                        {/* </td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              {/* pagination */}
              {userApplicationData && userApplicationData.length > 15 ? (
                <>
                  <div className="pagination" style={{ marginTop: "-30px" }}>
                    <nav aria-label="Page navigation example">
                      <ul className="pagination justify-content-center">
                        <li
                          className={`page-item ${page <= 1 ? "disabled" : ""}`}
                        >
                          <p
                            tabIndex="-1"
                            onClick={() => handlePageChange(page - 1)}
                            disabled={page === 1}
                          >
                            <IoIosArrowBack
                              className={
                                page <= 1
                                  ? "paginationBtndisabled"
                                  : "paginationBtn"
                              }
                              style={{ marginTop: "-2px" }}
                            />
                          </p>
                        </li>
                        <li className="page-item disabled">
                          <p>{`${page} / ${totalPage}`}</p>
                        </li>
                        <li
                          className={`page-item ${
                            page >= totalPage ? "disabled" : ""
                          }`}
                        >
                          <p
                            onClick={() => handlePageChange(page + 1)}
                            disabled={page === totalPage}
                          >
                            <IoIosArrowForward
                              className={
                                page >= totalPage
                                  ? "paginationBtndisabled"
                                  : "paginationBtn"
                              }
                              style={{ marginTop: "-2px" }}
                            />
                          </p>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </>
              ) : (
                <></>
              )}
            </>
          ) : (
            <>
              <i
                class="fa fa-window-close-o mx-auto"
                aria-hidden="true"
                style={{ fontSize: "225px", color: "#343434" }}
              ></i>
              <h1 style={{ marginTop: "-30px", color: "#343434" }}>
                No Application Found
              </h1>

              <div style={{ textAlign: "center", marginTop: "-20px" }}>
                <button
                  className="NoApplicationButtons"
                  onClick={() => navigate("/matchedJobs/all")}
                >
                  Explore Jobs
                </button>
                <button
                  className="NoApplicationButtons2"
                  onClick={() => navigate("/matchedInternships/all")}
                >
                  Explore Internships
                </button>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}

export default UserApplications;
