import React from 'react'

const Story = () => {
  return (
    <div className="terms-of-use" style={{ backgroundColor: "white" }}>
        <div style={{ maxWidth: "900px", marginLeft: "auto", marginRight: "auto", paddingTop: "50px", paddingBottom: "50px", textAlign: 'justify' }} className="about-us-mobile-responsive">
        <h4 style={{ marginTop: "20px", marginBottom: "20px", fontSize: "14px" }}>
        Post a Project
        </h4>
        <p style={{fontSize:'14px'}}>
        Post a project to tell us about your project. We’ll quickly match you with awesome freelancers and skillsets.
        </p>
        <br/>
        <br/>
        <h4 style={{ marginTop: "20px", marginBottom: "20px", fontSize: "14px" }}>
        Interview
        </h4>
        <p style={{fontSize:'14px'}}>
        Browse proposals, profiles of freelancers & their reviews. Compare, interview & finalise the candidate.
        </p>
        <br/>
        <br/>
        <h4 style={{ marginTop: "20px", marginBottom: "20px", fontSize: "14px" }}>
        Hire
        </h4>
        <p style={{fontSize:'14px'}}>
        Assign projects to the finalized freelancer on Blackcoffer Freelance and pay them after the successful delivery of milestones, tasks, and projects. 
        </p>
        <br/>
        <br/>
        </div>
    </div>
  )
}

export default Story