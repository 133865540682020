import React from "react";
import Select from "react-select";
import "./css/projectcategory.css";

const ProjectSpecializationSelector = ({
  setSelectedOption,
  optionData,
  selectedData,
}) => {
  const handleChange = (option) => {
    setSelectedOption(option);
  };
  return (
    <div className="job-select-container">
      <Select
        options={optionData}
        onChange={handleChange}
        value={selectedData}
        classNamePrefix="react-select"
        placeholder="Choose specialization"
      />
    </div>
  );
};

export default ProjectSpecializationSelector;
