import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "./css/LandingPage.scss";
import underline_fire from "../../assets/images/underline_fire.png";
import homepageline from "../../assets/images/homepageline.png";
import vo2 from "../../assets/images/vo.png";
import homeline from "../../assets/images/home _line_page.png";
import homeline2 from "../../assets/images/home _line_page2.png";
import homegifline from "../../assets/images/homegifline.gif";
import homegifline3 from "../../assets/images/homegifline3.gif";
import latesthomepageline from "../../assets/images/latesthomepageline.gif";
import Internship from "./InternShip";
import Jobs from "./Jobs";
import Recommended from "./Recommended";
import Spinner from "../Layout/Spinner";

// import data from '../../test.jobs.json';

export default function LandingPage() {
  const { user } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const getData = async () => {
    setLoading(true);
    try {
      const res = await fetch("/api/user/getAllJobs");
      const data = await res.json();
      // Sort the jobs array by datePosted in descending order
      const sortedJobs = data.jobs.sort(
        (a, b) => new Date(b.datePosted) - new Date(a.datePosted)
      );
      setData(sortedJobs);
      //console.log(sortedJobs);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  /* const recommendedData = data.sort(
    (a, b) => b.userApplied.length - a.userApplied.length
  );*/

  const recommendedData = data;
  useEffect(() => {
    getData();
  }, []);

  console.log(recommendedData, "recommm data");

  return (
    <div style={{ backgroundcolor: "white" }}>
      {user ? (
        <div className="main-header">
          <div
            className="main-heading"
            style={{ marginTop: "40px", textAlign: "center" }}
          >
            <h1>Hi, {user.name}!</h1>
            <svg
              className="handicon"
              xmlns="http://www.w3.org/2000/svg"
              width="29"
              height="28"
              viewBox="0 0 29 28"
              fill="none"
            >
              <g clipPath="url(#clip0_2124_49662)">
                <path
                  d="M9.35515 2.78756C10.2217 2.69535 11.1184 3.29919 11.4242 4.07269L11.2964 2.78125C11.1858 1.75611 11.8125 1.05869 12.8387 0.947747C13.8637 0.838804 15.1938 3.38516 15.1938 3.38516C15.0825 2.35014 15.7022 1.45788 16.7376 1.34595C17.7719 1.23602 18.7025 1.98442 18.813 3.02077L20.3446 16.6393L14.7362 24.719L8.84488 17.9623L7.67226 4.87761C7.55891 3.83536 8.3126 2.89988 9.35515 2.78756Z"
                  fill="#EF9645"
                />
                <path
                  d="M4.90809 7.44123C4.90809 7.44123 4.76036 5.92791 6.27398 5.78121C7.78627 5.63378 7.93371 7.14607 7.93371 7.14607L8.61745 14.1687C8.84872 14.0342 9.08983 13.9085 9.34477 13.794L8.39514 4.04828C8.39514 4.04828 8.24771 2.53599 9.76066 2.38893C11.273 2.24149 11.4204 3.75379 11.4204 3.75379L12.3131 12.9214C12.5591 12.8766 12.8095 12.8333 13.0637 12.7956L12.0293 2.1666C12.0293 2.1666 11.8819 0.654305 13.3948 0.50724C14.9071 0.359808 15.0546 1.8721 15.0546 1.8721L16.0897 12.4998C16.3497 12.4878 16.5988 12.4907 16.852 12.488L15.8844 2.55453C15.8844 2.55453 15.737 1.04224 17.2493 0.894807C18.7616 0.747375 18.909 2.25967 18.909 2.25967L19.9318 12.7634L20.087 14.3607C15.5491 14.8033 12.8926 18.879 13.2606 22.6599C13.3338 23.4162 14.0901 23.343 14.0901 23.343C13.6486 18.8048 16.3787 15.4847 20.9169 15.0431L22.6257 9.64892C22.6257 9.64892 22.9691 8.16869 24.449 8.51107C25.9293 8.85448 25.5865 10.3351 25.5865 10.3351L24.8714 14.2831C24.5878 15.8484 24.3354 17.4248 24.2753 19.0144C24.1057 23.5028 20.6416 27.2868 16.0466 27.7352C11.0352 28.223 6.57516 24.5544 6.08637 19.5433L4.90809 7.44123Z"
                  fill="#FFDC5D"
                />
              </g>
              <defs>
                <clipPath id="clip0_2124_49662">
                  <rect
                    width="28"
                    height="28"
                    fill="white"
                    transform="translate(0.5)"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>
          <h4>Let’s help you land your dream career</h4>
        </div>
      ) : (
        <>
          <div className="main-header">
            <h1 style={{ marginTop: "40px", textAlign: "center" }}>
              Transform your dream career into an achievable reality
            </h1>
            {/* <img className="underline" src={underline_fire} alt=""  style={{width: "40px"}}/> */}
            <img className="underline" src={latesthomepageline} alt="" />
          </div>
        </>
      )}

      {loading ? <Spinner /> : <Recommended data={recommendedData} />}

      {loading ? (
        <Spinner />
      ) : (
        <Jobs data={data.filter((item) => item.opportunityType === "Hourly")} />
      )}

      {loading ? (
        <Spinner />
      ) : (
        <Internship
          data={data.filter((item) => item.opportunityType === "Fixed Cost")}
        />
      )}
    </div>
  );
}
