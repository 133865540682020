import React from 'react';
import { Helmet } from 'react-helmet';

const Layout = ({ children }) => {
    return (
        <div>
            <Helmet>
                <meta property="og:title" content="Workcroft | Connecting Talent to the World’s Work" />
            </Helmet>
            {children}
        </div>
    );
};

export default Layout;

