import React, { useEffect, useState } from "react";
import "./EmployerNew/css/postInternship.css";
import axios from "axios";
import Select from "react-select";

const SkillsSelector = ({
  handleSelect,
  isOptional,
  value,
  placeholder = "List the key skills and deliverables",
}) => {
  const [allskills, setAllSkills] = useState([]);
  //const [selectedSkill, setSelectedSkill] = useState("");
  const [isTyping, setIsTyping] = useState(false);
  const [searchTerm2, setSearchTerm2] = useState("");

  const handleInputChange2 = (newValue) => {
    setSearchTerm2(newValue);
    setIsTyping(newValue.length > 0);
  };
  const customStyles = {
    dropdownIndicator: (provided) => ({
      ...provided,
      display: "none",
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: "none",
    }),
  };
  const handleSkillChange = (selectedOptions) => {
    // setSelectedSkill(selectedOptions);
    handleSelect(selectedOptions);
    // setJob({
    //   ...job,
    //   skill: selectedOptions.map((option) => option.value),
    // });
  };

  useEffect(() => {
    async function fetchAllSkills() {
      if (searchTerm2.trim()) {
        try {
          const response = await axios.get(`/api/data/skills`, {
            params: { keyword: searchTerm2 },
          });
          if (Array.isArray(response.data)) {
            setAllSkills(
              response.data.map((skill) => ({ label: skill, value: skill }))
            );
          } else {
            console.error("Unexpected response format:", response.data);
          }
        } catch (error) {
          console.error("Error fetching skills:", error);
        }
      } else {
        setAllSkills([]);
      }
    }
    fetchAllSkills();
  }, [searchTerm2]);

  return (
    <div className="details">
      <Select
        placeholder={placeholder}
        className="select-tag"
        isMulti
        value={value}
        onChange={handleSkillChange}
        options={allskills}
        onInputChange={handleInputChange2}
        menuPortalTarget={document.body}
        styles={{
          menuPortal: (base, state) => ({
            ...base,
            zIndex: 9999,
          }),
        }}
        menuIsOpen={isTyping}
      />
    </div>
  );
};

export default SkillsSelector;
