import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "../Layout/Spinner";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import "./UserAppliedJob.scss";
import "./PreviewJob.scss";
import "./Preview.css";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import bulb from "../../assets/images/bulbicon.png";
import { loadUser } from "../../actions/userActions";
import { Helmet } from "react-helmet";
import Popup from "reactjs-popup";
import { RxCounterClockwiseClock, RxCross1 } from "react-icons/rx";
import {
  addEllipsis,
  calculateDaysDifference,
  determineBgColor,
  determineColor,
  extractIdFromUrl,
  formatDate,
  formatDate2,
  timeAgo,
} from "../../utils/helper";
import PropagateLoader from "react-spinners/PropagateLoader";
import Select from "react-select";
import circle from "../../assets/images/circle.png";
import money from "../../assets/images/money.png";
import calendar from "../../assets/images/calendar.png";
import game from "../../assets/images/game.png";
import arrowup from "../../assets/images/Arrowup.jpg";
import paint from "../../assets/images/paint.png";
import { FiUsers } from "react-icons/fi";
import { MONTH_DETAILS } from "../data";
import CloseButton from "./CloseButton";
import { MdDeleteOutline, MdOutlineAddCircleOutline } from "react-icons/md";

function UserAppliedJob() {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo(0, 0);
  }, []);

  const location = useLocation();
  const { user } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const [state, setState] = useState(null);
  const [allProfile, setAllProfile] = useState([]);
  const [selectedProfile, setSelectedProfile] = useState("");
  const [userBid, setUserBid] = useState("");
  const [addPortfolioModal, setAddPortfolioModal] = useState(false);
  const [resumeData, setResumeData] = useState("");
  const [selectedPortfolioProject, setSelectedPortfolioProject] = useState([]);
  const [hoveredProject, setHoveredProject] = useState("");
  const [portfolioProjectData, setPortfolioProjectData] = useState([]);

  const handleSelectProjectPortfolio = (data) => {
    if (selectedPortfolioProject?.length > 0) {
      if (selectedPortfolioProject?.some((r) => r?._id === data?._id)) {
        let t = selectedPortfolioProject?.filter(
          (item) => item?._id !== data?._id
        );
        setSelectedPortfolioProject(t);
      } else {
        if (selectedPortfolioProject?.length <= 3) {
          setSelectedPortfolioProject([...selectedPortfolioProject, data]);
        } else {
          toast.warn("You have only select 4 profiles");
        }
      }
    } else {
      setSelectedPortfolioProject([...selectedPortfolioProject, data]);
    }
  };

  const handleBidChange = (e) => {
    const inputValue = e.target.value;
    if (/^\d*$/.test(inputValue)) {
      setUserBid(inputValue);
    }
  };

  const next30Days = new Date();
  next30Days.setDate(next30Days.getDate() + 30);
  useEffect(() => {
    dispatch(loadUser());
  }, [dispatch]);
  // if (state == null) {
  //   navigate("/");
  // }
  const [applyJob, setApplyJob] = useState({
    _id: state?._id,
    coverLetter: "",
    availability: true,
    specifyAvailability: "",
    assessment: [],
  });

  const handleChange = (e) => {
    setApplyJob({
      ...applyJob,
      [e.target.name]: e.target.value,
    });
  };

  const available = () => {
    setApplyJob({
      ...applyJob,
      availability: true,
    });
  };

  const handleAssesmentChange = (e, data) => {
    // Check if the question already exists in the assessment array
    const questionIndex = applyJob.assessment.findIndex(
      (item) => item.Question === data
    );

    if (questionIndex !== -1) {
      // If the question exists, update its answer
      setApplyJob((prevState) => ({
        ...prevState,
        assessment: prevState.assessment.map((item, index) => {
          if (index === questionIndex) {
            return {
              ...item,
              Answer: e.target.value,
            };
          }
          return item;
        }),
      }));
    } else {
      // If the question does not exist, push a new object into the array
      setApplyJob((prevState) => ({
        ...prevState,
        assessment: [
          ...prevState.assessment,
          {
            Question: data,
            Answer: e.target.value,
          },
        ],
      }));
    }
  };

  const getAnswerForKey = (questionKey) => {
    const foundQuestion = applyJob.assessment.find(
      (item) => item.Question === questionKey
    );
    return foundQuestion ? foundQuestion.Answer : ""; // Return answer if question is found, otherwise return empty string
  };

  const notAvailable = () => {
    setApplyJob({
      ...applyJob,
      availability: false,
    });
  };
  const handleBuyConnects = () => {
    navigate("/candidatebuyconnects");
    setShowModal(false);
  };

  // console.log(user, "this is user data");

  const handleApplyJob = async () => {
    if (user && user) {
      if (user && (!user.phone || !user.availability)) {
        toast.error("Please update your profile");
        navigate("/editProfileUser");
      } else if (user && !user.resume) {
        toast.error("Please create your resume");
        navigate("/profile");
      } else {
        if (!applyJob.coverLetter) {
          setLoading(false);
          toast.error("Cover Letter is required!!");
          return;
        }
        if (!selectedProfile) {
          setLoading(false);
          toast.error("Profile is required!!");
          return;
        }
        if (!userBid) {
          setLoading(false);
          toast.error("You Bid budget is required!!");
          return;
        }
        setLoading(true);
        // console.log(selectedProfile, "this is selected Profile");
        try {
          const response = await axios.post(
            "/api/user/apply",
            {
              _id: state?._id,
              coverLetter: applyJob.coverLetter,
              availability: applyJob.availability,
              specifyAvailability: applyJob.specifyAvailability,
              assessment: applyJob.assessment,
              profle: selectedProfile?.value,
              userBid: userBid,
              profilePortfolio: selectedPortfolioProject,
            },
            {
              headers: {
                "Content-Type": "application/json",
                token: localStorage.getItem("token"),
              },
            }
          );

          console.log(response, "res");
          setLoading(false);

          if (response.status === 201) {
            toast.success("Application Saved Successfully!", {
              style: {
                paddingTop: "18px",
                paddingBottom: "18px",
              },
            });
            const timer = setTimeout(() => {
              navigate("/applications");
            }, 3000);
            timer();
          } else if (
            response.status === 200 &&
            response.data.message === "Insufficient Credit"
          ) {
            // toast.error("Insufficient Credit! Please buy connects to apply.", {
            //   style: {
            //     padding: "18px",
            //   },
            // });
            setShowModal(true);
          }
        } catch (error) {
          console.log(error, "ahjsdjkhashd");
          setLoading(false);
          toast.error(error.response.data, {
            style: {
              padding: "18px",
            },
          });
        }
      }
    } else {
      toast.error("Please Login!!");
    }
  };

  //console.log(extractIdFromUrl(location?.pathname), "id");
  const getResumeData = async () => {
    try {
      const { data } = await axios.get("/api/user/getResume", {
        headers: {
          "Content-Type": "application/json",
          token: localStorage.getItem("token"),
        },
      });
      setResumeData(data.resume);
      setPortfolioProjectData(data?.resume?.Portfolio);
      //setSelectedPortfolioProject(data?.resume?.Portfolio[0]);
    } catch {
      toast.error("Something went wrong");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await axios.post("/api/user/previewdetails", {
          Id: extractIdFromUrl(location?.pathname),
        });
        console.log(data, "single");
        setState(data);
        setAllProfile([
          { value: data?.projectCategory, label: data?.projectCategory },
          {
            value: data?.projectSpecialization,
            label: data?.projectSpecialization,
          },
        ]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
    getResumeData();
  }, [location]);

  // console.log(state, "project data");
  console.log(resumeData, "resume data");

  return (
    <>
      <Helmet>
        <meta
          property="og:title"
          content={`${state?.profile} ${
            state?.jobType === "Remote" ? state?.jobType + " " : " "
          } ${state?.opportunityType}`}
        />
        <title>
          {state?.profile +
            " " +
            (state?.jobType === "Remote" ? state?.jobType + " " : " ") +
            state?.opportunityType}
        </title>
      </Helmet>

      {state === null ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "500px",
          }}
        >
          <PropagateLoader
            color={"#4B92C8"}
            loading={true}
            size={15}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      ) : (
        <div
          className="appliedjobBody"
          style={{
            backgroundcolor: "red",
            overflow: addPortfolioModal ? "hidden" : "auto",
          }}
        >
          <div className="pt-2">
            <div className="step-content container">
              <div
                className="sectionn  mb-5 mx-auto"
                style={{ maxWidth: "900px", backgroundColor: "transparent" }}
              >
                <div
                  className="section1 "
                  style={{ boxShadow: "none", border: "none" }}
                >
                  <p
                    className=" text-start "
                    style={{
                      marginTop: "20px",
                      fontSize: "30px",
                      lineHeight: "35px",
                      fontWeight: "600",
                    }}
                  >
                    {state && state?.profile}
                  </p>
                  <hr
                    style={{
                      marginTop: "30px",
                      marginBottom: "32px",
                      height: "0.05px",
                      color: "gray",
                    }}
                  />

                  <div>
                    <p style={{ fontSize: "25px", fontWeight: "500" }}>
                      Submit a Proposal
                    </p>
                    <hr
                      style={{
                        marginTop: "30px",
                        height: "0.05px",
                        color: "gray",
                      }}
                    />
                    <p style={{ fontSize: "22px", fontWeight: "500" }}>
                      Proposal settings
                    </p>
                    <p>Propose with a specialization and category</p>
                    <div>
                      <Select
                        className="drop-Down"
                        options={allProfile}
                        onChange={(vl) => {
                          //console.log(vl);
                          setSelectedProfile(vl);
                        }}
                        placeholder="General Profile"
                        value={selectedProfile && selectedProfile}
                      />

                      <p style={{ fontSize: "14px" }}>
                        This proposal requires{" "}
                        {state?.opportunityType === "Hourly" ? 8 : 4} Connects
                      </p>
                      <p style={{ fontSize: "14px" }}>
                        When you submit this proposal, you'll have{" "}
                        {state?.opportunityType === "Hourly"
                          ? user?.connectCredit - 8
                          : user?.connectCredit - 4}{" "}
                        Connects remaining.
                      </p>
                      {user?.connectCredit <
                        (state?.opportunityType === "Hourly" ? 8 : 4) && (
                        <span style={{ fontSize: "14px", color: "red" }}>
                          {" "}
                          ***Insufficient Connects to submit a proposal for the
                          project.{" "}
                          <span
                            onClick={() =>
                              navigate("/candidateconnectsinformation")
                            }
                            style={{ color: "#1e85df", cursor: "pointer" }}
                          >
                            Buy Connects
                          </span>
                        </span>
                      )}
                    </div>
                  </div>
                  <hr
                    style={{
                      marginTop: "30px",
                      height: "0.05px",
                      color: "gray",
                    }}
                  />
                  <div>
                    <div className="project-details-conatiner">
                      <div
                        style={{
                          width: "124px",
                          borderRadius: "5px",
                          fontSize: "13px",
                          textAlign: "center",
                          fontWeight: "500",
                          padding: "3px",
                          color: "#787878",
                          border: "solid 1px #D1D1D1",
                        }}
                      >
                        <img
                          src={arrowup}
                          alt="arrow"
                          style={{
                            marginRight: "5px",
                            height: "17px",
                            marginTop: "-4px",
                          }}
                        />
                        Actively hiring
                      </div>
                      <div className="row">
                        {/* <div className="col-9"> */}
                        <div className="responsive-col">
                          <h3
                            style={{ fontSize: "17px" }}
                            className="title pt-3"
                          >
                            {state?.profile}
                          </h3>
                        </div>
                      </div>
                      <div className="content mb-5" style={{}}>
                        <div
                          className="py-4 "
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            gap: "20px 70px",
                          }}
                        >
                          <div className="statusBoxes">
                            <p>
                              <span>
                                <img
                                  src={circle}
                                  alt="circle"
                                  style={{ width: "14px" }}
                                />
                              </span>
                              <span className="mx-2 heeading">START DATE</span>
                            </p>
                            <h3 className="wfm123">
                              {state.startDate ===
                              "Immediately (within next 30 days)" ? (
                                <>Immediately</>
                              ) : (
                                <>{state?.startDate}</>
                              )}
                            </h3>
                          </div>

                          <div className="statusBoxes">
                            <p>
                              <span>
                                <img
                                  src={calendar}
                                  alt="calendar"
                                  style={{ width: "14px" }}
                                />
                              </span>
                              <span className="mx-2 heeading">DURATION</span>
                            </p>
                            <h3 className="wfm123">
                              {state?.duration}{" "}
                              {state?.durationType || "months"}
                            </h3>
                          </div>

                          {state?.opportunityType === "Hourly" ? (
                            <>
                              <div className="statusBoxes">
                                <p>
                                  <span>
                                    <img
                                      src={money}
                                      alt="money"
                                      style={{ width: "14px" }}
                                    />
                                  </span>
                                  <span className="mx-2 heeading">BUDGET</span>
                                </p>
                                {state?.salary === 0 || null ? (
                                  <>
                                    <h3 className="head">Unpaid</h3>
                                  </>
                                ) : (
                                  <>
                                    <h3
                                      className="wfm123"
                                      style={{ fontSize: "14px" }}
                                    >
                                      ${state?.salary}
                                      {state?.salaryMax ? (
                                        <> - ${state?.salaryMax} per hour</>
                                      ) : (
                                        <></>
                                      )}
                                    </h3>
                                  </>
                                )}
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="statusBoxes">
                                <p>
                                  <span>
                                    <img
                                      src={money}
                                      alt="money"
                                      style={{ width: "14px" }}
                                    />
                                  </span>
                                  <span className="mx-2 heeading">BUDGET</span>
                                </p>
                                <>
                                  {state?.salaryProfile === "Unpaid" ? (
                                    <>
                                      <h3 className="head">Unpaid</h3>
                                    </>
                                  ) : (
                                    <>
                                      {state?.salaryProfile ===
                                      "Performance based" ? (
                                        <>
                                          <h3
                                            className="wfm123"
                                            style={{ fontSize: "14px" }}
                                          >
                                            ${state?.salary} Fixed Cost + $
                                            {state?.incentive} per{" "}
                                            {state?.incentiveType}
                                          </h3>
                                        </>
                                      ) : (
                                        <>
                                          {state?.salaryProfile ===
                                          "Negotiable" ? (
                                            <h3
                                              className="wfm123"
                                              style={{ fontSize: "14px" }}
                                            >
                                              ${state?.salary} - $
                                              {state?.salaryMax} Fixed Cost
                                            </h3>
                                          ) : (
                                            <h3
                                              className="wfm123"
                                              style={{ fontSize: "14px" }}
                                            >
                                              ${state?.salary} Fixed Cost
                                            </h3>
                                          )}
                                        </>
                                      )}
                                    </>
                                  )}
                                </>
                              </div>
                            </>
                          )}

                          <div className="statusBoxes">
                            <p>
                              <span>
                                <img
                                  src={game}
                                  alt="game"
                                  style={{ width: "14px" }}
                                />
                              </span>
                              <span className="mx-2 heeading">Apply by</span>
                            </p>
                            <h3 className="wfm123">
                              {state?.deadline.slice(8, 10) +
                                " " +
                                MONTH_DETAILS[
                                  parseInt(state?.deadline.slice(5, 7), 10)
                                ] +
                                "' " +
                                state?.deadline.slice(2, 4)}
                            </h3>
                          </div>
                          <div className="col-lg-2 col-md-2 col-sm-3"></div>
                        </div>
                        <div className="row py-2 px-1">
                          <span>
                            {/* <span>
                              {calculateDaysDifference(
                                state?.datePosted
                              ).toFixed(0) < 6 ? (
                                <>
                                  {calculateDaysDifference(
                                    state?.datePosted
                                  ).toFixed(0) === 0 ? (
                                    <span
                                      className=" post1"
                                      style={{
                                        backgroundColor: "#E1FFE1",
                                        color: "green",
                                      }}
                                    >
                                      <RxCounterClockwiseClock
                                        style={{
                                          fontSize: "14px",
                                          marginTop: "-2px",
                                        }}
                                      />{" "}
                                      Posted {timeAgo(state?.datePosted)}
                                    </span>
                                  ) : (
                                    <span
                                      className=" post1"
                                      style={{
                                        backgroundColor: "#E1FFE1",
                                        color: "green",
                                      }}
                                    >
                                      <RxCounterClockwiseClock
                                        style={{
                                          fontSize: "14px",
                                          marginTop: "-2px",
                                        }}
                                      />{" "}
                                      Posted {timeAgo(state?.datePosted)}
                                    </span>
                                  )}
                                </>
                              ) : (
                                <>
                                  {calculateDaysDifference(
                                    state?.datePosted
                                  ).toFixed(0) > 5 &&
                                  calculateDaysDifference(
                                    state?.datePosted
                                  ).toFixed(0) < 11 ? (
                                    <>
                                      <span
                                        className=" post1 "
                                        style={{
                                          backgroundColor: "#E2F8FF",
                                          color: "#0D8CB8",
                                        }}
                                      >
                                        <RxCounterClockwiseClock
                                          style={{
                                            fontSize: "14px",
                                            marginTop: "-2px",
                                          }}
                                        />{" "}
                                        Posted{" "}
                                        {calculateDaysDifference(
                                          state?.datePosted
                                        ).toFixed(0)}{" "}
                                        days ago
                                      </span>
                                    </>
                                  ) : (
                                    <>
                                      <span className=" post1 ">
                                        <RxCounterClockwiseClock
                                          style={{
                                            fontSize: "14px",
                                            marginTop: "-2px",
                                          }}
                                        />{" "}
                                        Posted{" "}
                                        {calculateDaysDifference(
                                          state?.datePosted
                                        ).toFixed(0)}{" "}
                                        days ago
                                      </span>
                                    </>
                                  )}
                                </>
                              )}
                            </span>{" "} */}
                            <span
                              className=" post1"
                              style={{
                                backgroundColor: determineBgColor(
                                  state.datePosted
                                ),
                                color: determineColor(state.datePosted),
                              }}
                            >
                              <RxCounterClockwiseClock
                                style={{
                                  fontSize: "14px",
                                  marginTop: "-2px",
                                }}
                              />{" "}
                              Posted {timeAgo(state.datePosted)}
                            </span>
                            <span
                              className="post1"
                              style={{ marginLeft: "10px" }}
                            >
                              {state?.opportunityType}
                            </span>
                            {state?.isPartTimeAllowed === "Yes" ? (
                              <span
                                className="post1"
                                style={{ marginLeft: "10px" }}
                              >
                                Part time
                              </span>
                            ) : (
                              <></>
                            )}
                          </span>
                          <div className="row">
                            <div className="col">
                              <p
                                className="mt-5"
                                style={{ width: "max-content" }}
                              >
                                <FiUsers
                                  style={{
                                    fontSize: "22px",
                                    color: "#9b9b9b",
                                    marginTop: "-3px",
                                  }}
                                />
                                <span
                                  className="head"
                                  style={{
                                    fontSize: "15px",
                                    marginLeft: "5px",
                                  }}
                                >
                                  {state?.userApplied &&
                                  state?.userApplied.length > 0 ? (
                                    <>{state?.userApplied.length}</>
                                  ) : (
                                    <>0</>
                                  )}{" "}
                                  applicants
                                </span>
                              </p>
                            </div>
                            <hr
                              style={{
                                height: "0.05px",
                                color: "gray",
                                width: "98%",
                                marginLeft: "10px",
                                marginTop: "10px",
                                marginBottom: "10px",
                              }}
                            />
                            <div style={{ paddingLeft: "25px" }}>
                              <h3
                                className=" pt-2"
                                style={{ fontSize: "17px" }}
                              >
                                About the project
                              </h3>
                              <p
                                className="wfm123"
                                style={{
                                  marginTop: "20px",
                                  whiteSpace: "pre-wrap",
                                  lineHeight: "25px",
                                }}
                              >
                                {state?.jobDescription}
                              </p>
                            </div>
                            <hr
                              style={{
                                height: "0.05px",
                                color: "gray",
                                width: "98%",
                                marginLeft: "10px",
                                marginTop: "10px",
                                marginBottom: "10px",
                              }}
                            />
                            <div style={{ paddingLeft: "25px" }}>
                              <h3
                                className="title pt-2"
                                style={{ fontSize: "17px" }}
                              >
                                Additional Candidate Preference
                              </h3>
                              <p
                                className="wfm123"
                                style={{
                                  marginTop: "20px",
                                  whiteSpace: "pre-wrap",
                                  lineHeight: "25px",
                                }}
                              >
                                {state?.preference}
                              </p>
                            </div>
                            <hr
                              style={{
                                height: "0.05px",
                                color: "gray",
                                width: "98%",
                                marginLeft: "10px",
                                marginTop: "10px",
                                marginBottom: "10px",
                              }}
                            />
                            <div style={{ paddingLeft: "25px" }}>
                              <h3
                                className="title pt-2 "
                                style={{ fontSize: "17px" }}
                              >
                                Who can apply
                              </h3>
                              <p
                                className="wfm123"
                                style={{ marginTop: "20px" }}
                              >
                                Only those candidates can apply who :
                              </p>
                              <p className="wfm123">
                                1. are available for the{" "}
                                {state?.jobType === "Remote" ? (
                                  <>work from home</>
                                ) : (
                                  <></>
                                )}{" "}
                                {state?.opportunityType.toLowerCase()}{" "}
                                {state?.jobType === "Remote" ? (
                                  <></>
                                ) : (
                                  <>
                                    {state?.city && state?.city.length > 0 ? (
                                      <>in {state?.city[0].toLowerCase()}</>
                                    ) : (
                                      <></>
                                    )}
                                  </>
                                )}
                              </p>
                              <p className="wfm123">
                                2. can start the{" "}
                                {/* {state2.jobType === 'Remote' ? <>work from home</> : <></>}{' '}
            {state2.opportunityType.toLowerCase()}{' '} */}
                                {state?.jobType === "Remote" ? (
                                  <>work from home</>
                                ) : (
                                  <></>
                                )}{" "}
                                {state?.opportunityType.toLowerCase()}{" "}
                                {state?.startDate ===
                                "Immediately (within next 30 days)" ? (
                                  <>
                                    {formatDate(new Date())} and{" "}
                                    {formatDate(next30Days)}
                                  </>
                                ) : state.startDate === "Later" ? (
                                  <>
                                    internship between{" "}
                                    {state.internshipFrom && state.internshipTo
                                      ? `${formatDate2(
                                          state.internshipFrom
                                        )} and ${formatDate2(
                                          state.internshipTo
                                        )}`
                                      : ""}
                                  </>
                                ) : null}
                                {state.jobType === "Remote" ? (
                                  <></>
                                ) : (
                                  <>
                                    {state.city && state.city.length > 0 ? (
                                      <> in {state.city[0].toLowerCase()}</>
                                    ) : (
                                      <></>
                                    )}
                                  </>
                                )}{" "}
                                {/* {state2.startDate.toLowerCase()} */}
                              </p>
                              <p className="wfm123">
                                3. have relevant skills and interests
                              </p>
                              {state.opportunityType === "Fixed Cost" && (
                                <p className="wfm123">
                                  4. are available for duration of{" "}
                                  {state.duration} months
                                </p>
                              )}
                              <p className="wfm123">
                                * Women wanting to start/restart their career
                                can also apply.
                              </p>
                            </div>
                            <hr
                              style={{
                                height: "0.05px",
                                color: "gray",
                                width: "98%",
                                marginLeft: "10px",
                                marginTop: "20px",
                                marginBottom: "10px",
                              }}
                            />
                            <div style={{ paddingLeft: "25px" }}>
                              <h3
                                className="title"
                                style={{ fontSize: "17px", marginTop: "35px" }}
                              >
                                Project Category :{" "}
                                <span
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "normal",
                                  }}
                                >
                                  {state?.projectCategory}
                                </span>
                              </h3>
                            </div>
                            <hr
                              style={{
                                height: "0.05px",
                                color: "gray",
                                width: "98%",
                                marginLeft: "10px",
                                marginTop: "10px",
                                marginBottom: "10px",
                              }}
                            />
                            <div style={{ paddingLeft: "25px" }}>
                              <h3
                                className="title"
                                style={{ fontSize: "17px", marginTop: "35px" }}
                              >
                                Project Specialization :{" "}
                                <span
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "normal",
                                  }}
                                >
                                  {state?.projectSpecialization}
                                </span>
                              </h3>
                            </div>
                            <hr
                              style={{
                                height: "0.05px",
                                color: "gray",
                                width: "98%",
                                marginLeft: "10px",
                                marginTop: "10px",
                                marginBottom: "10px",
                              }}
                            />
                            <div style={{ paddingLeft: "25px" }}>
                              <div className="mt-5">
                                <h3
                                  className="title pt-2"
                                  style={{ fontSize: "17px" }}
                                >
                                  Skill(s) required
                                </h3>
                                {state?.skill?.map((data, index) => (
                                  <p className="post1234 mr-4 mt-3">{data}</p>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="project-details-conatiner"
                    style={{ paddingBottom: "10px" }}
                  >
                    <p style={{ fontSize: "22px", fontWeight: "500" }}>Term</p>
                    <p style={{ fontSize: "20px", fontWeight: "500" }}>
                      What is the rate you'd like to bid for this project
                    </p>
                    <div className=" rate-conatainer">
                      <p style={{ fontSize: "14px" }}>
                        Your profile rate :{" "}
                        {resumeData?.PersonalDetails?.minimumRate} per hour
                      </p>
                      {state?.opportunityType === "Hourly" ? (
                        <p style={{ fontSize: "14px" }}>
                          Client budget : ${state?.salary}.00 - $
                          {state?.salaryMax}.00 per hour
                        </p>
                      ) : (
                        <p style={{ fontSize: "14px" }}>
                          {" "}
                          Client budget : ${state?.salary}.00
                        </p>
                      )}
                    </div>
                    <div className=" rate-conatainer">
                      <div>
                        <p style={{ fontSize: "17px", fontWeight: "500" }}>
                          {state?.opportunityType}
                        </p>
                        <p style={{ marginTop: "-10px", fontSize: "14px" }}>
                          Total amount the client will see on your proposal
                        </p>
                      </div>
                      <div style={{ display: "flex" }}>
                        <input
                          className="budget-input"
                          onChange={handleBidChange}
                          value={userBid}
                          placeholder="Enter bid amount"
                        />
                        <p style={{ color: "gray", marginLeft: "4px" }}>
                          {state?.opportunityType === "Hourly"
                            ? "  per hour"
                            : ""}
                        </p>
                      </div>
                    </div>
                  </div>

                  <p
                    style={{
                      fontSize: "25px",
                      fontWeight: "500",
                      marginTop: "50px",
                    }}
                  >
                    Cover letter
                  </p>

                  <div class="form-group mt-4">
                    <p
                      style={{
                        fontWeight: "400",
                        fontSize: "15px",
                        marginTop: "10px",
                      }}
                    >
                      Why should you be hired for this project?
                    </p>
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        marginTop: "-7px",
                      }}
                    ></div>
                  </div>

                  <div>
                    <Accordion
                      className="accordianfortab"
                      style={{ backgroundColor: "#e0ffe5", boxShadow: "none" }}
                    >
                      <AccordionSummary
                        expandIcon={
                          <ExpandMoreIcon
                            style={{ color: "#009ACB", fontSize: "25px" }}
                          />
                        }
                        aria-controls="panel1-content"
                        id="panel1-header"
                      >
                        <img
                          src={bulb}
                          alt="bulb"
                          style={{
                            width: "27px",
                            height: "27px",
                            marginTop: "-5px",
                            marginRight: "6px",
                          }}
                        />
                        {/* <span style={{color: "#009ACB"}}>See tips to answer this question</span> */}
                        <span style={{ color: "#009ACB" }}>
                          Check out these tips to answer this question
                          effectively:
                        </span>
                      </AccordionSummary>
                      <AccordionDetails style={{ marginTop: "-25px" }}>
                        <hr style={{ height: "0.1px", color: "#009ACB" }} />
                        <ul style={{ fontSize: "14px", marginTop: "15px" }}>
                          {/* <li style={{marginTop: "10px"}}>
            <span style={{fontWeight: "500"}}>Highlight your strengths</span> - mention any experience (internship/job experience or extra-curricular activities),
            accomplishments, skills that are relevant to the role.
          </li> */}
                          <li
                            style={{ marginTop: "10px", listStyleType: "disc" }}
                          >
                            <span style={{ fontWeight: "500" }}>
                              Highlight what excites you about the role and the
                              company.
                            </span>
                          </li>
                          {/* <li style={{marginTop: "10px"}}>
          <span style={{fontWeight: "500"}}> Show enthusiasm</span> - mention what excites you about this role and the company. You can go through their website/
            social media to understand what interests you.
          </li> */}
                          <li
                            style={{ marginTop: "10px", listStyleType: "disc" }}
                          >
                            <span style={{ fontWeight: "500" }}>
                              Connect your personal values, career goals, and
                              passions to what you’ve learned about the company.
                            </span>
                          </li>
                          <li
                            style={{ marginTop: "10px", listStyleType: "disc" }}
                          >
                            <span style={{ fontWeight: "500" }}>
                              Research their website and social media to
                              identify specific aspects that interest you.
                            </span>
                          </li>
                          {/* <li style={{marginTop: "10px"}}>
          <span style={{fontWeight: "500"}}>Research their website and social media to identify specific aspects that interest you.</span>
          </li> */}
                          <li
                            style={{ marginTop: "10px", listStyleType: "disc" }}
                          >
                            <span style={{ fontWeight: "500" }}>
                              Mention any projects, initiatives, or company
                              culture elements that resonate with you.
                            </span>
                          </li>
                          <li
                            style={{ marginTop: "10px", listStyleType: "disc" }}
                          >
                            <span style={{ fontWeight: "500" }}>
                              Explain how the role aligns with your skills and
                              career aspirations.
                            </span>
                          </li>
                          <li style={{ marginTop: "10px" }}>
                            <span style={{ fontWeight: "500" }}>
                              Craft your response authentically
                            </span>
                          </li>
                          {/* <li style={{marginTop: "10px"}}>
          <span style={{fontWeight: "500"}}> Do not copy answers</span> from the internet.
          </li> */}
                        </ul>
                      </AccordionDetails>
                    </Accordion>
                  </div>

                  <div class="" style={{ marginTop: "10px" }}>
                    <textarea
                      style={{
                        fontSize: "14px",
                        letterSpacing: "1px",
                        padding: "12px",
                        lineHeight: "23px",
                      }}
                      class="form-control"
                      id="coverLetter"
                      rows="7"
                      name="coverLetter"
                      value={applyJob.coverLetter}
                      onChange={handleChange}
                      placeholder="Before employers even glance at your resume, they often encounter your response to this question. Approach it thoughtfully, incorporating pertinent details such as your skills, experience, and what draws you to the role."
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="step-content container"
              style={{ marginTop: "-130px" }}
            >
              <div className="sectionn mx-auto" style={{ maxWidth: "900px" }}>
                <div
                  className="section1 "
                  style={{ boxShadow: "none", border: "none" }}
                >
                  <p style={{ fontSize: "25px", fontWeight: "500" }}>
                    Your availability
                  </p>

                  <p
                    style={{
                      fontWeight: "400",
                      fontSize: "16px",
                      marginBottom: "20px",
                      color: "#545454",
                      letterSpacing: "0.4px",
                    }}
                  >
                    Confirm your availability
                  </p>
                  <div class="form-check">
                    <input
                      class="form-check-input "
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault1"
                      onClick={available}
                    />
                    <p
                      style={{
                        fontWeight: "400",
                        fontSize: "15px",
                        color: "#545454",
                        letterSpacing: "0.4px",
                      }}
                    >
                      Yes, I am available to join immediately
                    </p>
                  </div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault2"
                      onClick={() => {
                        notAvailable();
                      }}
                    />
                    <p
                      style={{
                        fontWeight: "400",
                        fontSize: "15px",
                        color: "#545454",
                        letterSpacing: "0.4px",
                      }}
                    >
                      <p>
                        {" "}
                        No{" "}
                        <span style={{ color: "#929292" }}>
                          (Please specify your availability)
                        </span>
                      </p>
                    </p>
                  </div>
                  <hr
                    style={{
                      height: "0.05px",
                      color: "gray",
                      width: "100%",
                      marginTop: "40px",
                      marginBottom: "10px",
                    }}
                  />

                  {applyJob && applyJob.availability === true ? (
                    <></>
                  ) : (
                    <>
                      <div
                        class=""
                        style={{ marginTop: "15px", marginBottom: "20px" }}
                      >
                        <style>
                          {`
                    #specifyAvailability::placeholder {
                      color: #bababa;
                    }
                  `}
                        </style>
                        <textarea
                          style={{
                            fontSize: "14px",
                            letterSpacing: "1px",
                            padding: "12px",
                            lineHeight: "23px",
                          }}
                          class="form-control"
                          id="specifyAvailability"
                          rows="6"
                          name="specifyAvailability"
                          value={applyJob.specifyAvailability}
                          onChange={handleChange}
                          placeholder="eg. I am ready to start working in the New York office immediately. However, I will need a week to relocate."
                        ></textarea>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>

            <div
              className="step-content container"
              style={{ marginTop: "-115px" }}
            >
              <div className="sectionn mx-auto" style={{ maxWidth: "900px" }}>
                <div
                  className="section1 "
                  style={{ boxShadow: "none", border: "none" }}
                >
                  {state && state?.question && state?.question.q1 !== "" && (
                    <>
                      <p
                        style={{
                          fontSize: "25px",
                          fontWeight: "500",
                          marginBottom: "-20px",
                        }}
                      >
                        Assessment
                      </p>

                      {Object.keys(state?.question).map((key, index) =>
                        state?.question[key] !== "" ? (
                          <div key={key} className="form-group mt-5">
                            <p
                              style={{
                                fontWeight: "400",
                                fontSize: "16px",
                                color: "#545454",
                                letterSpacing: "0.3px",
                              }}
                            >
                              Q{index + 1}. {state?.question[key]}
                            </p>
                            <br />
                            <div style={{ marginTop: "-40px" }}>
                              <p
                                style={{
                                  color: "#929292",
                                  fontSize: "14px",
                                  marginTop: "10px",
                                  letterSpacing: "0.4px",
                                }}
                              >
                                If you want to share any documents or files,
                                Please upload it on
                                <a
                                  href="https://drive.google.com/drive/u/0/home"
                                  target="_blank"
                                  rel="noreferrer"
                                  style={{
                                    color: "#0986C4",
                                    cursor: "pointer",
                                    fontSize: "15px",
                                    marginLeft: "5px",
                                    marginRight: "3px",
                                  }}
                                >
                                  Google Drive
                                </a>{" "}
                                or
                                <a
                                  href="https://www.dropbox.com/"
                                  target="_blank"
                                  rel="noreferrer"
                                  style={{
                                    color: "#0986C4",
                                    cursor: "pointer",
                                    fontSize: "15px",
                                    marginLeft: "5px",
                                    marginRight: "5px",
                                  }}
                                >
                                  DropBox
                                </a>
                                and paste the public link in the answer.
                              </p>
                            </div>
                            <div className="" style={{ marginTop: "-2px" }}>
                              <textarea
                                style={{
                                  fontSize: "14px",
                                  letterSpacing: "1px",
                                  padding: "12px",
                                  lineHeight: "23px",
                                }}
                                className="form-control"
                                id="assessment"
                                rows="6"
                                name="assessment"
                                value={getAnswerForKey(state?.question[key])}
                                onChange={(e) =>
                                  handleAssesmentChange(e, state?.question[key])
                                } // assuming you need to pass the key to handleChange
                                placeholder="Enter text..."
                              ></textarea>
                            </div>
                          </div>
                        ) : null
                      )}
                    </>
                  )}
                </div>
                <div
                  className="section1 "
                  style={{
                    boxShadow: "none",
                    border: "none",
                    marginTop: "-20px",
                  }}
                >
                  <p style={{ fontSize: "25px", fontWeight: "500" }}>
                    Profile highlights
                  </p>
                  <div
                    style={{
                      marginTop: "0px",
                      border: "1px solid #d1d1d1",
                      borderRadius: "10px",
                      overflow: "hidden",
                      position: "relative",
                    }}
                  >
                    {selectedPortfolioProject?.length > 0 && (
                      <div
                        style={{
                          position: "absolute",
                          right: "15px",
                          top: "5px",
                        }}
                      >
                        <MdOutlineAddCircleOutline
                          onClick={() => setAddPortfolioModal(true)}
                          style={{
                            color: "#0277de",
                            cursor: "pointer",
                            fontSize: "30px",
                          }}
                        />
                      </div>
                    )}
                    {selectedPortfolioProject?.length > 0 ? (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "10px",
                          marginTop: "40px",
                        }}
                      >
                        {selectedPortfolioProject?.map((item, index) => (
                          <div
                            key={index}
                            style={{
                              marginTop: "20px",
                              marginBottom: "20px",
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <div
                              style={{
                                width: "200px",
                                height: "200px",
                                border: "2px solid #e3e3e3",
                                padding: "5px",
                                borderRadius: "10px",
                                cursor: "pointer",
                                position: "relative",
                              }}
                              onMouseOver={() => setHoveredProject(item?._id)}
                              onMouseLeave={() => setHoveredProject("")}
                            >
                              {hoveredProject === item?._id && (
                                <div
                                  style={{
                                    position: "absolute",
                                    top: "0px",
                                    right: "0px",
                                    bottom: "0px",
                                    left: "0px",
                                    borderRadius: "10px",
                                    backgroundColor: "rgba(0,0,0,0.2)",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      cursor: "pointer",
                                      flexDirection: "column",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      backgroundColor: "white",
                                      padding: "4px",
                                      borderRadius: "50px",
                                    }}
                                    onClick={() => {
                                      let f = selectedPortfolioProject?.filter(
                                        (d) => d?._id !== item?._id
                                      );
                                      setSelectedPortfolioProject(f);
                                    }}
                                  >
                                    <MdDeleteOutline
                                      style={{
                                        color: "#5eb0d7",
                                        fontSize: "26px",
                                      }}
                                    />
                                  </div>
                                </div>
                              )}
                              <img
                                alt="thumnail"
                                src={item?.selectedThumbnail?.url}
                                style={{
                                  height: "70%",
                                  width: "100%",
                                  borderRadius: "10px",
                                }}
                              />
                              <h3
                                style={{
                                  marginTop: "5px",
                                  fontSize: "16px",
                                  color: "#5eb0d7",
                                }}
                              >
                                {addEllipsis(item?.projectTitle, 40)}
                              </h3>
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div
                        onClick={() => setAddPortfolioModal(!addPortfolioModal)}
                        style={{
                          cursor: "pointer",
                          height: "100%",
                          width: "100%",
                          padding: "40px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          backgroundColor: "#f0f2f0",
                        }}
                      >
                        <img
                          alt="paint"
                          src={paint}
                          style={{ height: "50px", width: "50px" }}
                        />
                        <p>Add a portfolio project</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div
                className="center text-end mt-4"
                style={{
                  margin: "auto",
                  marginBottom: "-20px",
                  marginRight: "130px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  <p
                    style={{
                      color: "#5eb0d7",
                      marginRight: "50px",
                      cursor: "pointer",
                    }}
                    onClick={() => navigate("/")}
                  >
                    Cancel
                  </p>
                  <button
                    type="button"
                    onClick={handleApplyJob}
                    style={{
                      fontSize: "16px",
                      borderRadius: "3px",
                      paddingTop: "2px",
                    }}
                    class="btn btn-info02"
                  >
                    {loading ? (
                      <Spinner />
                    ) : (
                      <span>
                        Send for {state?.opportunityType === "Hourly" ? 8 : 4}{" "}
                        connects
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <>
        <div
          className={`modal fade ${addPortfolioModal ? "show d-block" : ""}`}
          tabIndex="-1"
          role="dialog"
          style={{ display: addPortfolioModal ? "block" : "none" }}
        >
          <div className="modal-dialog " role="document">
            <div className="modal-content">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "5px",
                  paddingLeft: "10px",
                  paddingTop: "15px",
                  paddingBottom: "0px",
                }}
              >
                <div style={{ width: "80%" }}>
                  <h5 style={{ fontSize: "20px", fontWeight: "500" }}>
                    Select Portfolio Project
                  </h5>
                  <div
                    style={{
                      display: "flex",
                      paddingBottom: "4px",
                    }}
                  >
                    <input
                      className="budget-input"
                      style={{ width: "90%" }}
                      placeholder="Search project"
                      // onFocus={() => {
                      //   console.log(portfolioProjectData, "darta");
                      // }}
                      onChange={(e) => {
                        let inputValue = e.target.value;
                        //console.log(inputValue);

                        if (inputValue?.length > 2) {
                          if (e.key === "Backspace") {
                            setPortfolioProjectData(resumeData?.Portfolio);
                          }
                          let searchedData = portfolioProjectData?.filter(
                            (dt) =>
                              dt?.projectTitle
                                .toLowerCase()
                                .includes(inputValue.toLowerCase()) ||
                              dt?.skills?.some((sm) =>
                                sm.value
                                  .toLowerCase()
                                  .includes(inputValue?.toLowerCase())
                              )
                          );
                          console.log(searchedData, "searched Data");

                          setPortfolioProjectData(searchedData);
                        } else {
                          setPortfolioProjectData(resumeData?.Portfolio);
                        }
                      }}
                    />
                  </div>
                  <p style={{ fontSize: "13px" }}>
                    Total projects you have {resumeData?.Portfolio?.length}
                  </p>
                </div>

                <CloseButton
                  className="close"
                  onClick={() => setAddPortfolioModal(false)}
                />
              </div>

              <div
                className="modal-body"
                style={{ overflowY: "scroll", height: "500px" }}
              >
                {portfolioProjectData?.map((item, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      onClick={() => handleSelectProjectPortfolio(item)}
                      style={{
                        width: "100%",
                        cursor: "pointer",
                        border: selectedPortfolioProject?.some(
                          (itm) => itm?._id === item?._id
                        )
                          ? "2px solid #0277de"
                          : "2px solid #e3e3e3",
                        padding: "5px",
                        borderRadius: "10px",
                        display: "flex",
                        flexDirection: "row",
                        marginBottom: "10px",
                      }}
                    >
                      <img
                        alt="thumbnail"
                        src={item?.selectedThumbnail?.url}
                        style={{
                          height: "100px",
                          width: "40%",
                          borderRadius: "10px",
                        }}
                      />
                      <h3
                        style={{
                          marginLeft: "5px",
                          width: "60%",
                          fontWeight: "normal",
                          fontSize: "16px",
                        }}
                      >
                        {addEllipsis(item?.projectTitle, 100)}
                      </h3>
                    </div>
                  </div>
                ))}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  style={{
                    paddingLeft: "20px",
                    paddingRight: "20px",
                    fontSize: "16px",
                  }}
                  onClick={() => setAddPortfolioModal(false)}
                >
                  Done
                </button>
              </div>
            </div>
          </div>
        </div>
        {addPortfolioModal && <div className="modal-backdrop fade show"></div>}
      </>
      <Popup
        open={showModal}
        closeOnDocumentClick
        onClose={() => setShowModal(false)}
      >
        <div className="overlay" style={{ background: "rgba(0, 0, 0, 0.4)" }}>
          <div className="popup-containernew">
            <div
              className="close-button12345"
              onClick={() => setShowModal(false)}
            >
              <RxCross1 />
            </div>
            <div className="success-message mx-3">
              Your Connect credit limit has been reached.
            </div>
            <div className="success-message1 mx-3">
              To continue posting, kindly purchase more Connects.
            </div>
            <div
              className="d-flex flex-column flex-md-row gap-5"
              style={{ marginTop: "10px", marginBottom: "20px" }}
            >
              <button
                type="button"
                className="submit-btn12345"
                onClick={handleBuyConnects}
              >
                Buy Connects
              </button>
            </div>
          </div>
        </div>
      </Popup>
      <ToastContainer position="top-center" autoClose={10000} />
    </>
  );
}

export default UserAppliedJob;
