import React from 'react'
import { GoDotFill } from 'react-icons/go'

const HowItWorks = () => {
    return (
        <div className="terms-of-use" style={{ backgroundColor: "white" }}>
            <div style={{ maxWidth: "900px", marginLeft: "auto", marginRight: "auto", paddingTop: "50px", paddingBottom: "50px", textAlign: 'justify' }} className="about-us-mobile-responsive">
                <h1 style={{ fontSize: "32px" }}>If you are hiring</h1>
                <br />
                <br />
                <h1 style={{ fontSize: "32px" }}>Easily find quality freelancers</h1>
                <br />
                <p style={{ fontSize: '14px', lineHeight: "30px" }}
                ><b>
                        On Workcroft, you’ll find a range of top talent, from programmers to designers, writers, data scientists, data engineers, analysts, researchers, and more.
                    </b></p>
                <br />
                <p style={{ fontSize: '14px', lineHeight: "30px" }}>
                    <GoDotFill className='mr-3' style={{ fontSize: "14px", marginLeft: '25px' }} />
                    Start by posting a job. Tell us about your project and the specific skills required.
                </p>
                <p style={{ fontSize: '14px', lineHeight: "30px" }}>
                    <GoDotFill className='mr-3' style={{ fontSize: "14px", marginLeft: '25px' }} />
                    Workcroft analyzes your needs. Our search functionality uses data science to highlight freelancers based on their skills, helping you find talent that’s a good match.
                </p>
                <p style={{ fontSize: '14px', lineHeight: "30px" }}>
                    <GoDotFill className='mr-3' style={{ fontSize: "14px", marginLeft: '25px' }} />
                    We send you a shortlist of likely candidates. You can also search our site for talent, and freelancers can view your job and submit proposals too.
                </p>
                <br />
                <h1 style={{ fontSize: "32px" }}>Hire the best freelancer</h1>
                <br />
                <p style={{ fontSize: '14px', lineHeight: "30px" }}>
                    <b>Invite candidates to submit bids, then interview and hire your choice.</b>
                </p>
                <br />
                <p style={{ fontSize: '14px', lineHeight: "30px" }}>
                    <GoDotFill className='mr-3' style={{ fontSize: "14px", marginLeft: '25px' }} />
                    Browse profiles. View finalists’ Workcroft profiles to see skills, portfolio, and more.
                </p>
                <p style={{ fontSize: '14px', lineHeight: "30px" }}>
                    <GoDotFill className='mr-3' style={{ fontSize: "14px", marginLeft: '25px' }} />
                    Review proposals. Evaluate bids, taking into account each freelancer’s qualifications, thought process, timeline, and overall cost.
                </p>
                <p style={{ fontSize: '14px', lineHeight: "30px" }}>
                    <GoDotFill className='mr-3' style={{ fontSize: "14px", marginLeft: '25px' }} />
                    Schedule a chat. Ask specific questions, determine who’s the best fit, and contract.
                </p>
                <br />
                <h1 style={{ fontSize: "32px" }}>Work efficiently, and effectively</h1>
                <br />
                <p style={{ fontSize: '14px', lineHeight: "30px" }}>
                    Each project includes a standalone workspace of your choice by your team and your freelancer, allowing you to:
                </p>
                <br />
                <p style={{ fontSize: '14px', lineHeight: "30px" }}>
                    <GoDotFill className='mr-3' style={{ fontSize: "14px", marginLeft: '25px' }} />
                    Send and receive files. Use any mode of communication, such as email, Skype, file sharing tools, Google, Dropbox, and more.
                </p>
                <p style={{ fontSize: '14px', lineHeight: "30px" }}>
                    <GoDotFill className='mr-3' style={{ fontSize: "14px", marginLeft: '25px' }} />
                    Share feedback in real time. Use any messaging tool and software such as email, Skype, etc. to communicate via text, chat, or video.
                </p>
                <p style={{ fontSize: '14px', lineHeight: "30px" }}>
                    <GoDotFill className='mr-3' style={{ fontSize: "14px", marginLeft: '25px' }} />
                    Use your favorite communication tool to chart and call with your team and freelancers.
                </p>
                <br />
                <h1 style={{ fontSize: "32px" }}>Pay easily, with peace of mind</h1>
                <br />
                <p style={{ fontSize: '14px', lineHeight: "30px" }}>
                    Pay your freelancer by the hour, or a fixed price for the entire project or for each milestones, or a fixed cost on a project-to-project basis.
                </p>
                <br />
                <p style={{ fontSize: '14px', lineHeight: "30px" }}>
                    <GoDotFill className='mr-3' style={{ fontSize: "14px", marginLeft: '25px' }} />
                    Pay using your favorite wire transfer site or software like PayPal, credit cards, debit cards, etc
                </p>
                <p style={{ fontSize: '14px', lineHeight: "30px" }}>
                    <GoDotFill className='mr-3' style={{ fontSize: "14px", marginLeft: '25px' }} />
                    Workcroft accepts payments from over 170 countries in their currency.
                </p>
                <p style={{ fontSize: '14px', lineHeight: "30px" }}>
                    <GoDotFill className='mr-3' style={{ fontSize: "14px", marginLeft: '25px' }} />
                    Only pay for work you authorize and approve.
                </p>
                <p style={{ fontSize: '14px', lineHeight: "30px" }}>
                    <GoDotFill className='mr-3' style={{ fontSize: "14px", marginLeft: '25px' }} />
                    Workcroft team sends invoices only after successful delivery of jobs, tasks, milestone, and projects.
                </p>
                <br />
                <br />
                <div style={{ height: '175px', backgroundColor: '#5BBC2E', textAlign: 'center', justifyContent: 'center', padding: '56px' }}>
                    <h1 style={{ color: 'white', fontWeight: 'bold', fontSize: '24px' }}>Ready to find a freelancer?</h1>
                    <p style={{ color: 'white', fontSize: '12px', border: '1px solid white', padding: '7px',display:'inline-block',marginTop:'5px'}}>Get Started</p>
                </div>
                <br/>
                <br/>
                <br/>
                <h1 style={{ fontSize: "30px" }}>If you are freelancing</h1>
                <br/>
                <br/>
                <h1 style={{ fontSize: "32px" }}>Find rewarding projects</h1>
                <br />
                <p style={{ fontSize: '14px', lineHeight: "30px" }}>
                Workcroft is a great place to find more clients and to run and grow your own freelance business.
                </p>
                <br/>
                <p style={{ fontSize: '14px', lineHeight: "30px" }}>
                    <GoDotFill className='mr-3' style={{ fontSize: "14px", marginLeft: '25px' }} />
                    Freedom to work on ideal projects. On Workcroft, you run your own business and choose your own clients and projects. Just finish your profile and we’ll highlight ideal jobs. Also search projects, and respond to client invitations.
                </p>
                <p style={{ fontSize: '14px', lineHeight: "30px" }}>
                    <GoDotFill className='mr-3' style={{ fontSize: "14px", marginLeft: '25px' }} />
                    Wide variety and high pay. Clients are now posting jobs in hundreds of skill categories, paying top prices for great work.
                </p>
                <p style={{ fontSize: '14px', lineHeight: "30px" }}>
                    <GoDotFill className='mr-3' style={{ fontSize: "14px", marginLeft: '25px' }} />
                    More and more success. The greater the success you have on projects, the more likely you are to get hired by clients who use Workcroft.
                </p>
                <br />

                <h1 style={{ fontSize: "32px" }}>Get hired quickly</h1>
                <br />
                <p style={{ fontSize: '14px', lineHeight: "30px" }}>
                Workcroft makes it easy to connect with clients and begin doing great work.
                </p>
                <br/>
                <p style={{ fontSize: '14px', lineHeight: "30px" }}>
                    <GoDotFill className='mr-3' style={{ fontSize: "14px", marginLeft: '25px' }} />
                    Streamlined hiring. Workcroft’s sophisticated algorithms highlight projects you’re a great fit for.
                </p>
                <p style={{ fontSize: '14px', lineHeight: "30px" }}>
                    <GoDotFill className='mr-3' style={{ fontSize: "14px", marginLeft: '25px' }} />
                    Top Rated and Rising Talent programs. Enjoy higher visibility with the added status of prestigious programs.
                </p>
                <p style={{ fontSize: '14px', lineHeight: "30px" }}>
                    <GoDotFill className='mr-3' style={{ fontSize: "14px", marginLeft: '25px' }} />
                    Do substantial work with top clients. Workcroft pricing encourages freelancers to use Workcroft for repeat relationships with their clients.
                </p>
                <br />

                <h1 style={{ fontSize: "32px" }}>Work efficiently, and effectively</h1>
                <br />
                <p style={{ fontSize: '14px', lineHeight: "30px" }}>
                With Workcroft, you have the freedom and flexibility to control when, where, and how you work. Each project includes an online workspace shared by you and your client, allowing you to:
                </p>
                <br/>
                <p style={{ fontSize: '14px', lineHeight: "30px" }}>
                    <GoDotFill className='mr-3' style={{ fontSize: "14px", marginLeft: '25px' }} />
                    Send and receive files. Deliver digital assets in a secure environment.
                </p>
                <p style={{ fontSize: '14px', lineHeight: "30px" }}>
                    <GoDotFill className='mr-3' style={{ fontSize: "14px", marginLeft: '25px' }} />
                    Share feedback in real time. Use Workcroft Messages to communicate via text, chat, or video.
                </p>
                <p style={{ fontSize: '14px', lineHeight: "30px" }}>
                    <GoDotFill className='mr-3' style={{ fontSize: "14px", marginLeft: '25px' }} />
                    Use our mobile app. Many features can be accessed on your mobile phone when on the go.
                </p>
                <br />

                <h1 style={{ fontSize: "32px" }}>Get paid on time</h1>
                <br />
                <p style={{ fontSize: '14px', lineHeight: "30px" }}>
                All projects include any payment method of your choice or client’s choice - helping ensure that you get paid for all work successfully completed through any payment method you or your client prefer.
                </p>
                <br/>
                <p style={{ fontSize: '14px', lineHeight: "30px" }}>
                    <GoDotFill className='mr-3' style={{ fontSize: "14px", marginLeft: '25px' }} />
                    All invoices and payments happen through any preferred payment method. Count on a simple and streamlined process.
                </p>
                <p style={{ fontSize: '14px', lineHeight: "30px" }}>
                    <GoDotFill className='mr-3' style={{ fontSize: "14px", marginLeft: '25px' }} />
                    Hourly and fixed-price projects. For hourly work, submit timesheets through Workcroft or any communication mode. For fixed-price jobs, set milestones and funds are released using any preferred payment methods.
                </p>
                <p style={{ fontSize: '14px', lineHeight: "30px" }}>
                    <GoDotFill className='mr-3' style={{ fontSize: "14px", marginLeft: '25px' }} />
                    Multiple payment options. Choose a payment method that works best for you, from direct deposit or PayPal to wire transfer and more.
                </p>
                <br />

                <h1 style={{ fontSize: "32px" }}>Service fees for freelancers</h1>
                <br />
                <p style={{ fontSize: '14px', lineHeight: "30px" }}>
                Workcroft charges freelancers a sliding fee based on the freelancer's lifetime billings with a specific client. In other words, the more business a freelancer has done with a client, the more they’ll earn.
                </p>
                <br/>
                <p style={{ fontSize: '14px', lineHeight: "30px" }}>
                    <GoDotFill className='mr-3' style={{ fontSize: "14px", marginLeft: '25px' }} />
                    4 Connects for fixed project proposal
                </p>
                <p style={{ fontSize: '14px', lineHeight: "30px" }}>
                    <GoDotFill className='mr-3' style={{ fontSize: "14px", marginLeft: '25px' }} />
                    8 Connects for hourly project proposal
                </p>
                <br />
                <br />
                <div style={{ height: '175px', backgroundColor: '#5BBC2E', textAlign: 'center', justifyContent: 'center', padding: '56px' }}>
                    <h1 style={{ color: 'white', fontWeight: 'bold', fontSize: '24px' }}>Ready to get hired?</h1>
                    <p style={{ color: 'white', fontSize: '12px', border: '1px solid white', padding: '7px',display:'inline-block',marginTop:'5px'}}>Become a freelancer</p>
                </div>
                <br/>
                <br/>
            </div>
        </div>
    )
}

export default HowItWorks